<script setup lang="ts">
import { IOrderOption } from '../types';

const orderTypeStore = useOrderTypeStore();
const fulfillmentController = useFulfillmentController();

function setOption(option: IOrderOption) {
  fulfillmentController.redirectFromFulfillment(option.value);
}
</script>

<template>
  <span class="kfc-text text-[40px] leading-9 text-balance text-center">{{ $t('SELECT_ORDER_TYPE') }}</span>

  <div class="flex flex-col order-types">
    <KioskButton
      v-for="(option, index) in orderTypeStore.orderOptions"
      :key="index"
      :icon="option.icon"
      class="py-8 my-5"
      color="outline"
      text-size="large"
      @on-click="setOption(option)"
    >
      {{ $t(option.label) }}
    </KioskButton>
  </div>
</template>
