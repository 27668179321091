import { useI18n } from 'vue-i18n';

import { AxiosError } from 'axios';

import { CouponValidation, CouponValidationResponse } from '../interfaces';

export const useCouponStore = defineStore(
  'coupon',
  () => {
    const { storeId, integration } = useKioskInfoStore();
    const languageStore = useLanguageStore();
    const cartStore = useCartStoreV2();
    const orderTypeStore = useOrderTypeStore();

    const promoCode = ref('');
    const loading = ref(false);
    const response = ref<CouponValidationResponse | null>(null);
    const err = ref('');
    const couponIds = ref<number[]>([]);
    const discount = ref<number>(0);
    const { t } = useI18n();

    /**
     * Validate Coupon
     * @param promoCode string
     */
    async function validatePromoCode() {
      const validationObj: CouponValidation = {
        storeId,
        integrationName: integration,
        promoCode: promoCode.value,
        couponIds: couponIds.value,
        couponValues: {
          timestamp: new Date().toLocaleString('sv'),
          language: languageStore.currentLanguage.toUpperCase(),
          orderInfo: {
            itemsQuantity: cartStore.items.length,
            fulfillmentMode: orderTypeStore.fulfillmentMode,
            orderTotal: {
              subtotal: cartStore.subtotal - cartStore.taxes,
              tax: cartStore.taxes,
            },
          },
        },
      };

      loading.value = true;
      try {
        const res = (await couponRequests.post('/coupons/validate', validationObj)) as CouponValidationResponse;

        response.value = res;
        discount.value = res.appliedCouponInfo.discount;
        couponIds.value = res.redeemedCoupons.map((coupon) => coupon.id);
      } catch (error: unknown) {
        console.log(error);
        if (error instanceof AxiosError && error.response?.data.message) {
          err.value = error.response?.data.message;
        } else {
          err.value = t('INTERNAL_ERROR');
        }
      } finally {
        loading.value = false;
      }
    }

    /**
     * Revalidate Coupon
     *  @param couponId number
     */
    async function revalidateCoupon() {
      if (!couponIds.value.length) return;
      const validationObj = {
        storeId,
        couponIds: couponIds.value,
        couponValues: {
          timestamp: new Date().toLocaleString('sv'),
          language: languageStore.currentLanguage.toUpperCase(),
          orderInfo: {
            itemsQuantity: cartStore.items.length,
            fulfillmentMode: orderTypeStore.fulfillmentMode,
            orderTotal: {
              subtotal: cartStore.subtotal - cartStore.taxes,
              tax: cartStore.taxes,
            },
          },
        },
      };

      loading.value = true;
      try {
        const res = (await couponRequests.post('/coupons/recalculate', validationObj)) as CouponValidationResponse;

        response.value = res;
        discount.value = res.appliedCouponInfo.discount;
        couponIds.value = res.redeemedCoupons.map((coupon) => coupon.id);
      } catch (error: unknown) {
        console.log(error);
        if (error instanceof AxiosError && error.response?.data.message) {
          err.value = error.response?.data.message;
        } else {
          err.value = t('INTERNAL_ERROR');
        }
      } finally {
        loading.value = false;
      }
    }

    function clearCoupon() {
      promoCode.value = '';
      loading.value = false;
      response.value = null;
      err.value = '';
      couponIds.value = [];
      discount.value = 0;
    }

    return {
      loading,
      couponIds,
      err,
      discount,
      response,
      promoCode,
      validatePromoCode,
      revalidateCoupon,
      clearCoupon,
    };
  },
);
