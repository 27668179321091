<script setup lang="ts">
const route = useRoute();
const menuController = useMenuController();
const languageStore = useLanguageStore();
const inactiveStoreV2 = useInactiveStoreV2();
const gtmStore = useGtmStore();

const { triggerStartOrder } = useGTMEventsComposableV2();

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  // Get menu
  const { storeId } = route.params;
  if (!storeId) return;
  await menuController.getMenu(storeId as string, languageStore.currentLanguage);

  // Generate transactionId
  gtmStore.startOrder();
  triggerStartOrder();
}

function beforeUnmount() {
  inactiveStoreV2.pauseChecking();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());
</script>

<template>
  <InactiveWrapper>
    <router-view />
  </InactiveWrapper>
</template>
