import { FulfillmentMode } from '@common/enums/FulfillmentMode';
import { IOrderOption } from '@/modules/order-type/types';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { t } = useI18n();

  // State
  const orderTypes = ref<IOrderOption[]>([
    { icon: 'eat-here', label: 'EAT_HERE', value: FulfillmentMode.DINE_IN },
    { icon: 'shopping-bag', label: 'TAKE_AWAY', value: FulfillmentMode.PICKUP },
  ]);

  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  const orderOptions = computed(() =>
    orderTypes.value.map((type) => ({
      ...type,
      label: type.label,
    })));

  function clearOrderType() {
    fulfillmentMode.value = FulfillmentMode.DINE_IN;
    orderTypes.value = [
      { icon: 'eat-here', label: 'EAT_HERE', value: FulfillmentMode.DINE_IN },
      { icon: 'shopping-bag', label: 'TAKE_AWAY', value: FulfillmentMode.PICKUP },
    ];
  }

  function orderType(tableNumber: string) {
    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return tableNumber ? `${t('TAKE_AWAY')} - ${t('TABLE')} ${tableNumber}` : t('TAKE_AWAY');
    }

    return tableNumber
      ? `${t('EAT_HERE')} - ${t('TABLE')} ${tableNumber}`
      : t('EAT_HERE');
  }

  return {
    orderTypes,
    orderOptions,
    orderType,
    fulfillmentMode,
    clearOrderType,
  };
});
