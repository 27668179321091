import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';

/* eslint-disable max-lines */
export const menuData = {
  menu: {
    categories: {
      'ab67eaf6-0070-434f-ad51-2ded0c7b54c4': {
        name: 'LO frontera NUEVO',
        description: '',
        itemIds: [
          '43044d25-ae94-42f6-891a-9f7544734234',
          '16dab74e-1c2a-4d2d-96b3-66253fbaca84',
        ],
        id: 'ab67eaf6-0070-434f-ad51-2ded0c7b54c4',
        photoIds: [],
      },
      'a7742de6-965d-4c44-aaee-37569748caac': {
        name: 'Temporada Sabores 100% Colombianos',
        description: '',
        itemIds: [
          '82b2abb5-ddbe-41b0-a6d7-94e0ba5576a8',
          '15360d83-e4c8-4155-ae74-e99e2f4c1836',
        ],
        id: 'a7742de6-965d-4c44-aaee-37569748caac',
        photoIds: ['https://blob-storage.com/photos/menu-photos/dd37abb1-3ec3-4835-ae58-acf1bee44885.jpeg'],
      },
      '7452a843-aae7-46ce-91b1-9459d64e3136': {
        name: 'ARMA TU PIZZA',
        description: '',
        itemIds: [
          '0b69efe8-5506-4438-a243-5c3d1b88c228',
          'd55b945f-fc03-4073-85bc-f178f0a8e862',
          'a43089f5-98ec-43a8-843e-40e95d78af16',
        ],
        id: '7452a843-aae7-46ce-91b1-9459d64e3136',
        photoIds: ['https://blob-storage.com/photos/menu-photos/3e84c032-9bcb-4bc5-a6d8-edaa4087a0c4.png'],
      },
      'ebff60da-09cf-466d-9798-0c6580bbc441': {
        name: 'Wings',
        description: '',
        itemIds: [
          '481fe4bf-b39d-420f-a02a-42b582f49370',
          '5539cfe2-f093-4869-bd7b-409f339989ee',
        ],
        id: 'ebff60da-09cf-466d-9798-0c6580bbc441',
        photoIds: ['https://storage.googleapis.com/kiosks-production/CMRWingsV2-Kiosk%2FBrandImages%2FbrandImage.png'],
      },
    },
    items: {
      '43044d25-ae94-42f6-891a-9f7544734234': {
        name: 'Pastel Chocoirresistible',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 129,
        },
        skuDetails: {
          skuSlug: 'pastel-chocoirresistible-alc-a:92064eb60bda4803861097c34a72a56c-2043:fb5cddf2-f9ad-4986-81dc-77527b4fbac7',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '43044d25-ae94-42f6-891a-9f7544734234',
        photoIds: [
          '',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '16dab74e-1c2a-4d2d-96b3-66253fbaca84': {
        name: 'Kruncher con Papas',
        description: 'Kruncher (1 Kruncher + 1 papas medianas)',
        modifierGroupIds: [
          '686cae9d-d25e-4a9a-8297-c0db5a714af0',
          '23fed109-20e5-4cc2-a180-2f81d99cecd8',
          '1b081087-e3d8-44c6-a6e3-2d02eb37843f',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 89,
        },
        skuDetails: {
          skuSlug: 'kruncher-con-papas-a:92064eb60bda4803861097c34a72a56c-c946:464f9f96-3ebb-4757-8f4f-130abade7363',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '16dab74e-1c2a-4d2d-96b3-66253fbaca84',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/c66b0c91-2ea6-428e-9529-97a8f86e7df4.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '82b2abb5-ddbe-41b0-a6d7-94e0ba5576a8': {
        name: 'Torta de Almojábana',
        description: 'Disfruta nuestra nueva torta húmeda a base de almojábana y bocadillo.',
        modifierGroupIds: [
          'eda8a7c0-d96a-4c29-98ee-bfa73b6dc27f',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 8900,
        },
        skuDetails: {
          skuSlug: 'torta-de-almojábana-a:cf13dd4899054fcbaa2d81c237a5ae5f-124d:f744de42-1f62-47c5-a948-dd78b5949120',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '82b2abb5-ddbe-41b0-a6d7-94e0ba5576a8',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/d6eb7ba2-902c-4c35-bac6-1e8ab58aa337.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '15360d83-e4c8-4155-ae74-e99e2f4c1836': {
        name: 'Nevado Oblea es',
        description: 'Disfruta una experiencia llena de alegría, sabor y tradición con nuestro nevado decorado con salsa de mora, arequipe y obleas.',
        modifierGroupIds: [
          'aa6cfffc-574b-4b53-b329-23127b430910',
          '550b413b-5332-4f50-b41d-5f5f6b2ed53b',
          'a8a4610c-7a5c-46aa-acda-a30924f36f7a',
          'd1a5364c-6770-491b-96a8-a872839ea766',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 14500,
        },
        skuDetails: {
          skuSlug: 'nevado-oblea-gr-a:cf13dd4899054fcbaa2d81c237a5ae5f-6ae4:c454703c-ec3a-48aa-bf6b-e65089d17d83',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '15360d83-e4c8-4155-ae74-e99e2f4c1836',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/dd37abb1-3ec3-4835-ae58-acf1bee44885.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '0b69efe8-5506-4438-a243-5c3d1b88c228': {
        name: 'Arma tu Personal Pizza',
        description: 'Pizza individual',
        modifierGroupIds: [
          'b59b67ad-a928-4c77-a5ce-1744171a10bb',
          '2e10346b-88d6-4eb3-a6d6-681b793e034f',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 69,
        },
        skuDetails: {
          skuSlug: 'pizza-personal-a:6f277c97d4fc45a7972f8a5c9a3f97ab-8097:693d8167-db06-4f36-a171-e09c9d65d770',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '0b69efe8-5506-4438-a243-5c3d1b88c228',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ec378fbc-b402-41cd-a480-1316a7423dd1.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd55b945f-fc03-4073-85bc-f178f0a8e862': {
        name: 'Arma tu Pizza (1 ingrediente)',
        description: '',
        modifierGroupIds: [
          '238a5ecb-824a-4eac-8fe4-7aec3966dcfa',
          '2e10346b-88d6-4eb3-a6d6-681b793e034f',
          '9e1c8e0b-1014-4335-8f1b-84cee4d30d2e',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 159,
        },
        skuDetails: {
          skuSlug: 'arma-tu-pizza-1-ingrediente-a:6f277c97d4fc45a7972f8a5c9a3f97ab-4696:f9d68cfa-fdb3-469a-bb1f-bf9b26648a09',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd55b945f-fc03-4073-85bc-f178f0a8e862',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/fef16d2a-5da7-40cd-8fd2-2c4a8be3caff.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a43089f5-98ec-43a8-843e-40e95d78af16': {
        name: '¡Mitad y Mitad! Basica',
        description: 'Arma tu pizza mitad/mitad',
        modifierGroupIds: [
          '8d62b4f8-4732-4e2b-bc2e-2149757cacf1',
          '2e10346b-88d6-4eb3-a6d6-681b793e034f',
          'b59d354e-7c76-487b-a50d-d9777d302c6c',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 159,
        },
        skuDetails: {
          skuSlug: 'pizza-metadmetad-a:6f277c97d4fc45a7972f8a5c9a3f97ab-8622:36835f8a-e4ca-4f10-9451-2888aa8fd266',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a43089f5-98ec-43a8-843e-40e95d78af16',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/3ccd00d8-1707-4a7c-a699-3e70ebfb0e98.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '481fe4bf-b39d-420f-a02a-42b582f49370': {
        name: 'Plato De Fruta',
        description: 'Melón, papaya, sandía y plátano (240 g)',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 109,
        },
        skuDetails: {
          skuSlug: 'plato-de-frutafruit-plate-a:56e398cc834e4371a6f825061be55b37-56c6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '481fe4bf-b39d-420f-a02a-42b582f49370',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/95aeae08-c6bc-41e2-b3df-b05da7dccca0.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '5539cfe2-f093-4869-bd7b-409f339989ee': {
        name: 'Jugos',
        description: '500ml',
        modifierGroupIds: [
          'daa03631-4e8e-4b42-b715-27503da2f823',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jugosjuices-a:56e398cc834e4371a6f825061be55b37-9a86',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '5539cfe2-f093-4869-bd7b-409f339989ee',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/6e637413-3cfd-4ff1-9401-9711e42ce46b.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f7630eb6-9775-4f4f-965b-746f0b2386a2': {
        name: 'Hamburguesa Kruncher',
        description: '',
        modifierGroupIds: [
          'ea9442ce-4207-4231-b245-2858a9b6d1b6',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:46e38ea7-7bfe-44c5-aaf2-24052c4a95a7:a6480043-513c-42db-bd30-815446e4d9d0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f7630eb6-9775-4f4f-965b-746f0b2386a2',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/4da73fd5-1bbc-4a4a-81be-1d05a3a6d86a.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '779ee5da-b019-4b76-a21f-75888e5f7f46': {
        name: 'Papas Medianas',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:73f87dd1-6e4a-4e87-80a9-97999919b885:e84a7c0d-ae28-4e08-b6a8-b059b929395f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '779ee5da-b019-4b76-a21f-75888e5f7f46',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/49b9947c-9ff6-497d-829a-bead79c9443d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '7911d8f6-97d7-4f37-ab8c-34e1a1efbe8d': {
        name: 'Puré individual',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'puré-individual-a:92064eb60bda4803861097c34a72a56c-3522:4da07ed0-fc96-42d7-b83d-3376e6a86ff1',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '7911d8f6-97d7-4f37-ab8c-34e1a1efbe8d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/98ff0e33-474b-46c3-9e69-687f0efcc8c9.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8a49e398-2be7-40a5-830e-3563ae0f8f93': {
        name: 'Ensalada Individual',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'ensalada-individual-a:92064eb60bda4803861097c34a72a56c-0f3a:5269ac6f-0182-4aaf-8924-8c29a94ce482',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8a49e398-2be7-40a5-830e-3563ae0f8f93',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/8d281eee-4998-49ee-8d47-2f2a70a030d2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '0ae1358c-1337-45f0-870f-6eb5a7e35eeb': {
        name: 'Cheesy Fries',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 15,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:a53102d7-529b-417e-bca4-b25d93c7eb3c:f3083c99-b476-467f-b0b5-2236add3e817',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '0ae1358c-1337-45f0-870f-6eb5a7e35eeb',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a5ceee09-b2f7-40e1-8bb0-ea99a30a3686.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd5c7b61a-10ec-4ece-8666-9dec193010dd': {
        name: 'Refresco Mediano',
        description: 'Refresco Mediano',
        modifierGroupIds: [
          '23e70d4c-5db9-4145-a7bb-f91dff048a9a',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 19,
        },
        skuDetails: {
          skuSlug: 'refresco-473-ml-copy-a:92064eb60bda4803861097c34a72a56c-9cfc:05fe8a56-ed3c-43d5-839a-89047dbdafb9',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd5c7b61a-10ec-4ece-8666-9dec193010dd',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2bee8f19-ab50-4c2e-8ad5-d67bc6b8145c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd848819a-c6af-4b27-8986-ece7a3b3cd17': {
        name: 'Bisquet',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 19,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:095786a3-684d-45f7-99da-d7f9f71a348f:a4efe7f1-8d23-452b-abff-1b5630b54636',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd848819a-c6af-4b27-8986-ece7a3b3cd17',
        photoIds: [
          'https://photos.tryotter.comß/menu-photos/3a92558f-1a93-4914-af2d-5146d8701adc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '86d7fa21-c15d-4afb-9211-e45dfb7be8a4': {
        name: 'Puré Individual',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:f495a5e9-0de5-4fb2-b3b0-14833b185739:cb346f09-1e2c-4f9f-a6aa-aecd3f7c262f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '86d7fa21-c15d-4afb-9211-e45dfb7be8a4',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/bc1c6e64-669d-497a-b6e1-94ac7a12bfc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a0da476d-eceb-49ad-8a46-ae00619bc52f': {
        name: 'Ensalada Individual',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:def29857-1a19-414d-abbc-8f5bdec047cc:3b3df377-70ec-4bb0-a139-f6f5899db711',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a0da476d-eceb-49ad-8a46-ae00619bc52f',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/8d281eee-4998-49ee-8d47-2f2a70a030d2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'da922447-4e67-4370-b1e4-69c4d20dd327': {
        name: 'Papas Fritas',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:97df66cb-da70-41ef-a97b-6efc859cfd5a:91630af3-8706-4656-99bb-6f1d502011bd',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'da922447-4e67-4370-b1e4-69c4d20dd327',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/49b9947c-9ff6-497d-829a-bead79c9443d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '39d2d735-f5bf-4d8b-b297-753dc6ac613a': {
        name: 'Sundae Chocolate',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:4722e6d9-f9f8-4a63-b6f1-a94e64c120e6:80714e96-6b81-432f-94d8-89b07adf720d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '39d2d735-f5bf-4d8b-b297-753dc6ac613a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/4fa460b9-bd59-4cec-ae2e-ff27578e7c2d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'ef64ab4b-9c6b-4a6c-9817-6e7b0b81a17c': {
        name: 'Sundae Fresa',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:34ad6dba-f9cb-4449-88c7-8f85d25e598c:cb349832-3d21-46af-b0ce-3940750ec2dd',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'ef64ab4b-9c6b-4a6c-9817-6e7b0b81a17c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/64dfa582-b31c-45d8-9575-71c8ce474423.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'aa2fa685-885b-4f17-ad88-97428aa9f7d3': {
        name: 'Sundae Caramelo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:3041e7ae-44df-4f1b-9f95-7ba3201e679c:3dc1e1f0-7ec7-45bd-be0b-4c8e693c0162',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'aa2fa685-885b-4f17-ad88-97428aa9f7d3',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/26b41ed5-0392-4eea-a75a-221ed91bb382.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9b668e02-76da-4bc5-aebb-5e17c29a8cca': {
        name: 'Pay de Manzana',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 25,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:46fc7827-63b7-4e9f-b297-fcc6d1c524de:d500c0cf-6947-4490-9e67-3d50dbce30e7',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9b668e02-76da-4bc5-aebb-5e17c29a8cca',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/307517d3-ba6a-49f2-9b9c-d3b1aac81637.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3547889a-4a8d-4506-8148-8be24ebf6985': {
        name: 'Caliente',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206964:31d19077-31ac-4dfe-a031-d07b52a0a645',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3547889a-4a8d-4506-8148-8be24ebf6985',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/744d91cb-c991-40aa-9e6b-4754fa08740e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      'f91e1aa2-98a8-4af3-8aae-d1a3bf88b5e1': {
        name: 'Frío',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206965:79915e08-22af-4b55-adc0-cda08f5aac09',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f91e1aa2-98a8-4af3-8aae-d1a3bf88b5e1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/86887d44-9432-4688-b20f-e5aff805f8dc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '4eb4f029-daa4-4501-a105-421d5cf6d9a8': {
        name: 'Mediano (300ml)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'mediano-300ml-a:12d1a6ddd39148d1bb20fccfd25e13ef-4d10:fbe94177-a98e-49e0-8755-1d3923dcbcdd',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '4eb4f029-daa4-4501-a105-421d5cf6d9a8',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/84fe9b36-8a96-4f5f-9d58-8927c321c1a7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '9203e654-1295-4df5-8fcc-f9d8af291f02': {
        name: 'Grande (415ml)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 2000,
        },
        skuDetails: {
          skuSlug: 'grande-415ml-a:12d1a6ddd39148d1bb20fccfd25e13ef-c841:f3636f53-1394-4ddf-bde3-5e342f11f0fa',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9203e654-1295-4df5-8fcc-f9d8af291f02',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/e7a52d78-ea26-45e6-9c16-9269d4beebf7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '390befda-5076-4eb8-95cf-91f2991985fe': {
        name: 'Café tradicional',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206949:ce512282-174f-46af-b4dd-0ba2882abc6d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '390befda-5076-4eb8-95cf-91f2991985fe',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/00324a4c-b06a-4c38-9043-e089c21040cf.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '6aa6f69e-4b26-4ad7-9177-1292c87d8cab': {
        name: 'Café descafeinado',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206950:a9095aa6-5138-4985-93ac-18880fcad23c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '6aa6f69e-4b26-4ad7-9177-1292c87d8cab',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/e0271085-a58d-4cbc-a453-9c5915238f8e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '199cd2d3-3413-4e94-a596-b21dd38e1ea3': {
        name: 'Con crema chantilly',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23573850:1ab10a4a-d7b3-4dd7-92fc-3d9dcc08931c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '199cd2d3-3413-4e94-a596-b21dd38e1ea3',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2e500d9c-9923-492f-b17b-2f8d8cf75ad7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      'e30dee65-5ada-43dd-9211-b703bb8e9564': {
        name: 'Sin crema chantilly',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23573851:28027182-6bae-442f-8de8-f2d0b773c4b1',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'e30dee65-5ada-43dd-9211-b703bb8e9564',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f881e47a-6d89-44e2-b45c-79612aada602.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '492ba2d9-c2d4-45a5-a801-300611531cf6': {
        name: 'Leche condensada',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206951:f6cd495d-fe03-495b-a5f0-b2d8528c1a6d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '492ba2d9-c2d4-45a5-a801-300611531cf6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/6fb01e8b-756f-4dd9-93d9-b0232e85aaa7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '5ef0f430-f81e-484b-9ea9-14e1e5bdb524': {
        name: 'Arequipe',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206952:f08c81fd-1d3e-4e8c-acae-1bf3b7329aea',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '5ef0f430-f81e-484b-9ea9-14e1e5bdb524',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/323dc4a4-78e3-4070-afd6-8cf1b0701d66.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      'f265e693-7f4c-4bea-9a6c-03ec234e23c1': {
        name: 'Chocolate',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206953:3e3ef7b8-ff1b-43e0-82db-5b103cfcaefd',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f265e693-7f4c-4bea-9a6c-03ec234e23c1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/412a2d5f-f3b9-4454-b007-f80504f069ed.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '4500f833-2f29-44cf-9be7-27ba24d798cf': {
        name: 'Saborizante de caramelo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206955:56295f3e-c54d-41d7-8417-81fa4b24e157',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '4500f833-2f29-44cf-9be7-27ba24d798cf',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/e7e8bce3-74e0-4607-b85b-2b4fc35f41aa.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '5973d475-c085-4b69-9f49-177813132413': {
        name: 'Adición de  Espresso',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206956:cc6daa4b-f223-4273-a7ef-1bcc1575b0b3',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '5973d475-c085-4b69-9f49-177813132413',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/3c6393ce-7f8e-4908-afbc-4b91c4437fe7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      'c3669c46-40b4-47d3-9475-e3a3165bb3f0': {
        name: 'Saborizante de vainilla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 3500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23206954:fe75696d-08ac-4611-a86a-64d887aba8b1',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c3669c46-40b4-47d3-9475-e3a3165bb3f0',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/614eb797-a606-4c6c-8841-02c61b549cf1.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '8895ce2a-c8c2-4dff-9ff9-84a709a69113': {
        name: 'Licor whisky',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 7500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23458000:ffbac6a6-ae72-4f6b-93ff-4d50ff7dbf31',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: true,
        },
        additionalCharges: [],
        id: '8895ce2a-c8c2-4dff-9ff9-84a709a69113',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/465c8bae-f2ac-4260-9662-5564f75688af.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      'ca32fea2-f60d-4419-981f-d5d24f909d6c': {
        name: 'Licor crema whisky',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'COP',
          amount: 7500,
        },
        skuDetails: {
          skuSlug: '8a64556d-bd12-4bde-ba5e-07ad3ddafd7f:23457999:f55a6a98-19e1-48c8-b6b9-0769543137e7',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: true,
        },
        additionalCharges: [],
        id: 'ca32fea2-f60d-4419-981f-d5d24f909d6c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/de157062-73f0-4206-b63a-d1f1a482ad05.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0.19,
          },
          isValueAddedTax: true,
        },
      },
      '5e9fdd0f-c57f-4b3d-8bfe-497ce6733ca2': {
        name: 'Personal Pan (Especialidad)',
        description: '',
        modifierGroupIds: [
          '8eaf170f-5fe6-4fe7-9325-58acf06838f0',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'personal-especialidad-a:6f277c97d4fc45a7972f8a5c9a3f97ab-a03e:d5156724-4157-4b9c-9e68-0f30aea22f06',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '5e9fdd0f-c57f-4b3d-8bfe-497ce6733ca2',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '0d242845-2a51-4867-a570-54ad11ec35fc': {
        name: 'Personal Pan (1 ingrediente)',
        description: '',
        modifierGroupIds: [
          '89c20802-5d6d-4919-aadf-32a6627f8178',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '01m3vbbysyef0rhsido6ta:personal-pan-1-ingrediente-a:byd8l9T8RaeXL4pcmj+Xqw:6d68d0e5-3251-4602-9c91-8621a13e66a1',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '0d242845-2a51-4867-a570-54ad11ec35fc',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8757bc47-fd91-42b4-9375-3329b4acfe60': {
        name: 'Personal Pan (2-4 Ingredientes)',
        description: '',
        modifierGroupIds: [
          '4a68ffdb-04a9-4299-a811-e26e6cbc316c',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 10,
        },
        skuDetails: {
          skuSlug: '0odanf2ptryesdjq5qxh9g:personal-pan-24-ingredientes-a:byd8l9T8RaeXL4pcmj+Xqw:40991718-6aa5-4107-a8f4-701cea51acc8',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8757bc47-fd91-42b4-9375-3329b4acfe60',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8148ac5e-9237-45b5-87f6-3c2bc069dc16': {
        name: 'Quepapas (12 pz)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: '3g4t7aloqpcy0wrjnengbq:quepapas-a:nlZjoNXrQ3ayUUMw4iuTQw:4c7e1321-fb10-40c6-9a48-6968ba90acc8:4df8bdb9-9bff-4f0d-8cfb-d5d834b74777',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8148ac5e-9237-45b5-87f6-3c2bc069dc16',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/78777582-cf45-42ca-990a-41de1290d405.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b624db0c-eb55-4b24-a45f-a8afc59d9f93': {
        name: 'Papas a la francesa (180 gramos)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: 'jwvwnpxhs0azb4lt0mnnoa:papas-gajo-loaded-a:nlZjoNXrQ3ayUUMw4iuTQw:6dea64ef-d5b4-479d-871c-971fff6e49ce:8f4cd1ff-b56a-41d4-8f4c-20acc223bdaa',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b624db0c-eb55-4b24-a45f-a8afc59d9f93',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f060ab30-e2f6-4bd9-8e70-c99275f58915.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '368e01b8-61a0-4b01-8b0f-97d2911304a4': {
        name: 'Papas Gajo Naturales (220 gramos)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: 'papas-gajo-a:9e5663a0d5eb4376b2514330e22b9343-c1c5:f04244d4-c141-4da2-a8f1-69b74832cca9:6c061ebb-16ea-4b29-92eb-d90c510b6bc5',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '368e01b8-61a0-4b01-8b0f-97d2911304a4',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/97151c39-430b-471d-ad2a-6f7b00121748.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '464c796c-1f46-4e1f-bfb4-386a59763592': {
        name: 'Bastones de Cajeta Coronado',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: 'mc7jtsvurqgvyjxjbqm6uq:bastones-de-cajeta-coronado-a:nlZjoNXrQ3ayUUMw4iuTQw:277ff111-0cf3-46a0-b26f-ee9fa56d74e9:b1ffdff1-b5a6-4fb9-8c5b-096d8bf9b7cb',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '464c796c-1f46-4e1f-bfb4-386a59763592',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7f8523a8-c3e7-488e-85ab-09e7fcd77a4e.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '014b0d35-3016-4fda-b446-a68989e560b1': {
        name: 'Bastones La Lechera®',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: 'lwezvbbuqniuh2huye9fpq:bastones-la-lechera-a:nlZjoNXrQ3ayUUMw4iuTQw:fe16caa7-11a2-4032-9b99-593e228e0cae:5b6d82b8-78fb-4978-a91b-d6f9083bdbc5',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '014b0d35-3016-4fda-b446-a68989e560b1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/28b690b5-3acd-4314-a3b2-eaf0048cd49c.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '76da3f6b-ff80-4faa-b699-c1d0b61da5b3': {
        name: 'Coca-Cola 2 Lts',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 50,
        },
        skuDetails: {
          skuSlug: 'cocacola-2-lts-a:9e5663a0d5eb4376b2514330e22b9343-8b3d:0a435bd9-1463-4054-bdbc-852f36e4d789:49187039-bda3-4f0e-af3f-ce1304586f49',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '76da3f6b-ff80-4faa-b699-c1d0b61da5b3',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1c344c8e-aaa8-43fe-b277-3341f7378b20.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'fb0d5284-40b8-4c5f-8aff-57651fcf2692': {
        name: 'MEDIANA (1 Ingrediente)',
        description: 'Crea tu pizza con una de nuestras famosas y deliciosas masas.',
        modifierGroupIds: [
          '52dffa7f-4f1e-41d5-ba17-326df1751d4e',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'mediana-de-1-ingrediente-a:9e5663a0d5eb4376b2514330e22b9343-096b:4988ae72-d556-4f9b-b66b-4b11bad9cdaa:1621c9e9-a937-416c-af3f-c826f929aad8',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'fb0d5284-40b8-4c5f-8aff-57651fcf2692',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/24a07ac3-3878-48c7-97fb-6a9e0ba5ebe0.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '5ff3993c-5e28-44d7-9be7-36e4921a5131': {
        name: 'GRANDE (1 ingrediente)',
        description: '',
        modifierGroupIds: [
          '033cf4a4-ef17-468e-8995-d11bb0b0e455',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'familiar-1-ingrediente-a:6f277c97d4fc45a7972f8a5c9a3f97ab-d1d9:04f0ab2d-9bf5-4e1e-8483-23c0f663e488',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '5ff3993c-5e28-44d7-9be7-36e4921a5131',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/295d057d-04df-4520-b99a-1e9719a7e9be.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '6c9acd50-73e7-4e27-a839-c40097cf9472': {
        name: 'GRAN HUT (1 Ingrediente)',
        description: '',
        modifierGroupIds: [
          '0208352b-a37e-4492-98f5-cca63adc89d3',
          'bedfe1ac-14cd-44db-89ed-58ef3aff1190',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 100,
        },
        skuDetails: {
          skuSlug: 'taamb4ukrfetm1suoixrg:gran-hut-1-ingrediente-a:byd8l9T8RaeXL4pcmj+Xqw:94c4004a-0bad-48a1-909a-4b047aaa6266',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '6c9acd50-73e7-4e27-a839-c40097cf9472',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/b2ef0682-0154-4a50-a82e-5c50adcaa021.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '43a21b35-35f8-491b-8b5e-0c350cbe6ca6': {
        name: 'Agrega unas deliciosas QuePapas por +$79.00',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 79,
        },
        skuDetails: {
          skuSlug: 'quepapas-12-pz-copy-a:6f277c97d4fc45a7972f8a5c9a3f97ab-bb69:714eb3ae-0063-4cae-91b2-f902300e55ed',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '43a21b35-35f8-491b-8b5e-0c350cbe6ca6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/78777582-cf45-42ca-990a-41de1290d405.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'fa722044-75e3-4624-b036-a9ee8fd17dcb': {
        name: '½ MEDIANA BASICA',
        description: '',
        modifierGroupIds: [
          '887e3f5f-b1ea-44a5-ae3b-9925b66fe69c',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'mediana-12-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6f75:8c7b0a98-5438-4b7e-a5db-9ee2bfbdf159',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'fa722044-75e3-4624-b036-a9ee8fd17dcb',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ac359e7f-8eb5-4656-835e-2d7b24849d94.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '05cb0e96-7e65-4baa-9b82-a576fef312f4': {
        name: '½ GRANDE BASICA',
        description: '',
        modifierGroupIds: [
          '987a55ad-7f15-4c1f-9060-130691aa9809',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 30,
        },
        skuDetails: {
          skuSlug: 'grande-12-a:6f277c97d4fc45a7972f8a5c9a3f97ab-395a:b4bfd7dc-acd9-4bbf-b6eb-93b5cfdf9022',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '05cb0e96-7e65-4baa-9b82-a576fef312f4',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ac359e7f-8eb5-4656-835e-2d7b24849d94.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'c04cfef8-5eae-4fce-b95c-4d2dd7b7ad53': {
        name: '½ GRAN HUT BASICA',
        description: '',
        modifierGroupIds: [
          'e7d23a0e-35a1-468f-bf45-f08733c68690',
          '137ee522-df78-44c9-b5b5-260c8cfd0627',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 90,
        },
        skuDetails: {
          skuSlug: '-½-gran-hut-a:6f277c97d4fc45a7972f8a5c9a3f97ab-9c3c:f5878587-8d74-4681-80ee-50c24cba8233',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c04cfef8-5eae-4fce-b95c-4d2dd7b7ad53',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/cf37cfca-4dc1-403b-adac-878cc30a2e6c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3101d752-e80f-41f7-a1ce-952aa3e78d32': {
        name: 'Agrega una Coca-Cola 2Lts por +$50.00',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 50,
        },
        skuDetails: {
          skuSlug: 'agrega-una-cocacola-2lts-por-+$5000-a:6f277c97d4fc45a7972f8a5c9a3f97ab-2265:f7d0c700-6b22-4fad-8a6a-da44a1f401f3',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3101d752-e80f-41f7-a1ce-952aa3e78d32',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1c344c8e-aaa8-43fe-b277-3341f7378b20.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f9027666-61ee-4416-a931-a81d0ce1db2d': {
        name: 'Naranja',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 95,
        },
        skuDetails: {
          skuSlug: 'naranjaorange-a:56e398cc834e4371a6f825061be55b37-c403',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f9027666-61ee-4416-a931-a81d0ce1db2d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f72b3eec-1abd-43fc-b1cc-efbab52e32ec.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2a80062c-40d2-47b2-9c9d-c27ef15caa15': {
        name: 'Toronja',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 95,
        },
        skuDetails: {
          skuSlug: 'toronjagrapefruit-a:56e398cc834e4371a6f825061be55b37-3ac4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2a80062c-40d2-47b2-9c9d-c27ef15caa15',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1f07e1ff-b9e3-49fb-bab4-ebca730d66ae.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd40ffeb7-9d21-48e3-b7e7-d439a1ae99d9': {
        name: 'SIN CATSUP',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:04cd66a8-642f-43ba-b9cc-5e59b30bc1f4:12b98c75-7657-48c4-bf67-16b868d9a24b',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd40ffeb7-9d21-48e3-b7e7-d439a1ae99d9',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/b37cad7c-7f90-4b16-b535-05d8618e40e5.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3e688119-9908-495a-b5b5-fd3667b0834c': {
        name: 'SIN MOSTAZA',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:672eb3d1-d151-4ffa-bd7d-886cd17b87c2:76fe1634-c7f1-435a-b95d-88a0f5c19c89',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3e688119-9908-495a-b5b5-fd3667b0834c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/da9c2451-b148-4a02-a45f-b1a0cd938e7b.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'be3390dd-e3bb-4008-acad-d1865b5c23ea': {
        name: 'SIN PEPINILLOS',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:49b4c2f5-2f45-4732-9097-471e3b9f7304:d709f4fa-885c-40f1-b915-e636b55d84c8',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'be3390dd-e3bb-4008-acad-d1865b5c23ea',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/dddcfd30-6d48-49f3-b669-7412b0b0eda4.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd34402dc-e62a-4b41-b10e-00df337c8483': {
        name: 'SIN CEBOLLA',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:1c54da39-fc13-46cd-b06b-5b5c77b8b64d:360578f0-7071-43bf-8a1a-9e492890c694',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd34402dc-e62a-4b41-b10e-00df337c8483',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/d2559a98-799a-4925-a0f9-123828237a78.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2937a434-c359-4968-8625-4a2bd5d663c0': {
        name: 'Pepsi®',
        description: '',
        modifierGroupIds: [
          '21e7cca7-ff64-479b-8031-6796ef6507e2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pepsi-a:92064eb60bda4803861097c34a72a56c-d783:3d136d2f-e781-4be7-b54f-fcfbd4e36558',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2937a434-c359-4968-8625-4a2bd5d663c0',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/646646b7-7f24-42c6-8b17-dfe287fc7fee.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1af2f402-8fb7-464d-9ef2-7a480937aeb5': {
        name: 'Pepsi® Black',
        description: '',
        modifierGroupIds: [
          '21e7cca7-ff64-479b-8031-6796ef6507e2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'sabor-cola-sin-azucar-a:92064eb60bda4803861097c34a72a56c-60ff:579702a7-c71b-4604-a701-2fdfee39ef75',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1af2f402-8fb7-464d-9ef2-7a480937aeb5',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/12920290-10d0-432e-b07d-3fa1c93aaf1d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '71e67412-3344-4e9f-a3f6-d8d4cf432e34': {
        name: '7up®',
        description: '',
        modifierGroupIds: [
          '21e7cca7-ff64-479b-8031-6796ef6507e2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'sabor-limon-a:92064eb60bda4803861097c34a72a56c-747d:400824d6-82fa-4887-8ede-32c8d789e939',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '71e67412-3344-4e9f-a3f6-d8d4cf432e34',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/4505f44b-6ca8-4cb8-8cb3-a90771529f84.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8d338e22-197a-4626-9dc8-bb4e51ecc5b9': {
        name: 'Manzanita®',
        description: '',
        modifierGroupIds: [
          '21e7cca7-ff64-479b-8031-6796ef6507e2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'sabor-manzana-a:92064eb60bda4803861097c34a72a56c-c9df:5c52ca76-d3a4-4c8a-bffb-46906af80a01',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8d338e22-197a-4626-9dc8-bb4e51ecc5b9',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7346ca1e-8a02-4ee4-8ed0-a0f2a3cb251c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd0f59534-81df-4fed-9a1d-6586d3283388': {
        name: 'Mirinda®',
        description: '',
        modifierGroupIds: [
          '21e7cca7-ff64-479b-8031-6796ef6507e2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'sabor-naranja-a:92064eb60bda4803861097c34a72a56c-5371:dba1ac31-322d-4361-be52-58724cdf16d0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd0f59534-81df-4fed-9a1d-6586d3283388',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/dc7a90d3-73ab-4c28-8665-637585d60139.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9eb86e24-624f-4496-8e3f-2028003ac767': {
        name: 'Agua',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'agua-a:92064eb60bda4803861097c34a72a56c-8c4e:4d9c7cef-5e71-4d7e-9cfe-0b6677bb937c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9eb86e24-624f-4496-8e3f-2028003ac767',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f17fe1d3-dd41-4fe8-b9cf-e624b2e86136.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2382ccfb-9417-4ce3-9851-a8e7890dff78': {
        name: 'Personal Pan Queso',
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'sgjr61q5txeaf2i6ooa1ta:personal-pan-queso-a:byd8l9T8RaeXL4pcmj+Xqw:4a3fd4d9-c70f-4155-809a-625c41dbc1e1',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2382ccfb-9417-4ce3-9851-a8e7890dff78',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1f78c03c-7afe-41ba-8a4d-0f8c5a65447f.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd7cfc8e6-28b7-40d6-9038-66f999938477': {
        name: 'Personal Pan Hawaiana',
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 10,
        },
        skuDetails: {
          skuSlug: '4yfxuu4s2m3cbknltparw:personal-pan-hawaiana-a:byd8l9T8RaeXL4pcmj+Xqw:8e3efa66-939c-4a94-bb05-5df46ae52a57',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd7cfc8e6-28b7-40d6-9038-66f999938477',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/5ceb44e1-435e-49fb-afd7-17e5f488d8b3.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3b505ff8-a09a-492b-ac25-3b976db87018': {
        name: 'Personal Pan Alambre',
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 10,
        },
        skuDetails: {
          skuSlug: 'jtamhdemqymjeszvcdvfpw:personal-pan-alambre-a:byd8l9T8RaeXL4pcmj+Xqw:04467837-7d44-4cf3-9351-abc60dd8e4ee',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3b505ff8-a09a-492b-ac25-3b976db87018',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7a6df1eb-919a-434c-b842-73d12d2d4574.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '36429f15-1c93-48ac-a546-55e2b2314f7d': {
        name: 'Personal Pan Pastor',
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 10,
        },
        skuDetails: {
          skuSlug: 'p1ndi41sttcm6sfzvkboxq:personal-pan-pastor-a:byd8l9T8RaeXL4pcmj+Xqw:8e3a7ed2-1159-43e8-a349-66cd5074935f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '36429f15-1c93-48ac-a546-55e2b2314f7d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2bb69be1-8e96-427c-ae47-5b78ac53b6ee.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '96e93beb-0ee9-41df-b966-cbfad22ed31a': {
        name: 'Personal Pan Mexicana',
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 10,
        },
        skuDetails: {
          skuSlug: '7yecqk3wqqshlyjzlj2bta:personal-pan-mexicana-a:byd8l9T8RaeXL4pcmj+Xqw:3ff8f435-850f-42cb-91e2-bce121473922',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '96e93beb-0ee9-41df-b966-cbfad22ed31a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7a6df1eb-919a-434c-b842-73d12d2d4574.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '6544ad72-8362-4cb8-be6e-8ab08589748f': {
        name: "Personal Pan Chessy Lover's",
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 20,
        },
        skuDetails: {
          skuSlug: 'cdt7ojisqvpuqdbdlrwia:personal-pan-chessy-lovers-a:byd8l9T8RaeXL4pcmj+Xqw:6fee7e36-ec4e-4ae2-bcd4-f3b93d0a33ca',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '6544ad72-8362-4cb8-be6e-8ab08589748f',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/dd85e7fb-73ab-4109-9ca3-4c391783fdbd.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9bedb971-b123-45a6-af17-2a2503f7b7d0': {
        name: "Personal Pan Deluxe Lover's",
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 20,
        },
        skuDetails: {
          skuSlug: 'gbwcreaatgyzj9yd1qjhg:personal-pan-deluxe-lovers-a:byd8l9T8RaeXL4pcmj+Xqw:1d75a874-0297-491b-b3c1-63365ef2fec6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9bedb971-b123-45a6-af17-2a2503f7b7d0',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/944af33c-a103-4606-aa2c-0695b2a32bfa.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '89a3b78f-c118-4e3b-98e9-da84bd8a16b2': {
        name: "Personal Pan Meat Lover's",
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 20,
        },
        skuDetails: {
          skuSlug: 'bvdy73oqcuf264eag/hlq:personal-pan-meat-lovers-a:byd8l9T8RaeXL4pcmj+Xqw:d31b305c-5d04-42fb-ba82-7e6fedc26be5',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '89a3b78f-c118-4e3b-98e9-da84bd8a16b2',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7b99430a-fa13-49ce-a2e1-6a88e56790d9.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '952b4cc9-4a16-448d-8cd6-6af15d21610a': {
        name: "Personal Pan Suprema Lover's",
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 20,
        },
        skuDetails: {
          skuSlug: 'gj9f/tiusgszmixgd0e5ia:personal-pan-suprema-lovers-a:byd8l9T8RaeXL4pcmj+Xqw:96d0e69d-4664-472a-aec2-8f7162580817',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '952b4cc9-4a16-448d-8cd6-6af15d21610a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/290389e5-127f-439f-9167-b097065fcb38.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '96f130d2-d346-4c1e-8578-708effdebc78': {
        name: "Personal Pan Veggie Lover's",
        description: '',
        modifierGroupIds: [
          '3d5478a0-ddd4-4852-86f4-88b69101c051',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 20,
        },
        skuDetails: {
          skuSlug: 'j/l9djnssomlgwuevcuh5a:personal-pan-veggie-lovers-a:byd8l9T8RaeXL4pcmj+Xqw:678b0c2f-8d56-4195-b1af-5744d0eeb2d4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '96f130d2-d346-4c1e-8578-708effdebc78',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a853c73d-4f2f-4727-a93b-37c0a3e5410c.jpeg',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'efb3d5b7-8d49-42cd-84aa-edbb270ba9a5': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-3db1:cc163105-d738-4bd9-bd8d-6f9790e47489',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'efb3d5b7-8d49-42cd-84aa-edbb270ba9a5',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'dd5d1879-c864-4242-ab96-5b279d436205': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-5c50:d21c90a3-9c4f-4ea3-8645-dbc35a45bf05',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'dd5d1879-c864-4242-ab96-5b279d436205',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8116775c-5c83-46ac-86fb-8fc6cf099e86': {
        name: 'Cebolla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'cebolla-a:6f277c97d4fc45a7972f8a5c9a3f97ab-1436:13e126ec-03a8-49ed-a03f-6db25a93c35d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8116775c-5c83-46ac-86fb-8fc6cf099e86',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f1ea24e4-4bb7-4d68-a485-a8f68befa7a2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b2b4dfd3-3670-45bc-8489-a75bfef323d6': {
        name: 'Pimiento Verde',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pimiento-verde-a:6f277c97d4fc45a7972f8a5c9a3f97ab-2720:8dce16d4-709c-4909-b465-8fcbab7b2632',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b2b4dfd3-3670-45bc-8489-a75bfef323d6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2fb0501d-2e62-4ac9-91bc-4a35aee64862.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'cf457f62-4c17-4685-a21c-414fd4ae93a2': {
        name: 'Aceituna Negra',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'aceituna-negra-a:6f277c97d4fc45a7972f8a5c9a3f97ab-44ef:22edc882-a819-451c-b345-54999e47c322',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'cf457f62-4c17-4685-a21c-414fd4ae93a2',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1b207bf0-24c9-44c4-9baf-385b39f1373e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '12f2f3c8-1414-4318-afa8-411463c6131a': {
        name: 'Carne de Puerco',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-puerco-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6444:508eb35c-acd2-405d-aa34-4ef8835fb019',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '12f2f3c8-1414-4318-afa8-411463c6131a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7325214e-c7a1-4433-96ad-b528f7683a2e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '13af2940-d809-4bc7-a674-db1bccaa6e51': {
        name: 'Carne Res',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-res-a:6f277c97d4fc45a7972f8a5c9a3f97ab-0902:b230e4be-86ff-4e7f-9764-5ece65603dd0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '13af2940-d809-4bc7-a674-db1bccaa6e51',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/fb2b68e9-1338-4ad3-9804-0b7af8c37d23.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '01f1d6fa-81f1-44a1-aa11-76f91fb382c6': {
        name: 'Salchicha',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'salchicha-a:6f277c97d4fc45a7972f8a5c9a3f97ab-5943:ea257b64-43ef-44d1-ba4e-0bc6aabd0039',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '01f1d6fa-81f1-44a1-aa11-76f91fb382c6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/222e3fd5-964e-4114-811a-0be4cf9d988d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2b78edfa-cc65-4d9d-9bcd-ad04ace5b9aa': {
        name: 'Peperoni',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'peperoni-a:6f277c97d4fc45a7972f8a5c9a3f97ab-7f1a:6829765a-8908-4491-891a-ec0c4b1dacf6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2b78edfa-cc65-4d9d-9bcd-ad04ace5b9aa',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/726eb78a-7786-4b81-a987-2be39c932fc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd40fef97-74c9-46a3-a032-5a0421c161b1': {
        name: 'Tocino',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'tocino-a:6f277c97d4fc45a7972f8a5c9a3f97ab-5fda:128b419e-f86c-4160-a583-89d48a795194',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd40fef97-74c9-46a3-a032-5a0421c161b1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f9569644-ac53-4b3c-b564-2d8aa381c35c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b10016ec-5870-4db8-96aa-ba09e465b4d8': {
        name: 'Jalapeno',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jalapeno-a:6f277c97d4fc45a7972f8a5c9a3f97ab-2faa:cf1ba888-cb77-49a7-941f-8307c09a4fd8',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b10016ec-5870-4db8-96aa-ba09e465b4d8',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a9e853c1-4f31-4e2b-a60f-f60abfcc94f5.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1e013271-b0df-4963-a76e-7d1ffab16557': {
        name: 'Piña',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'piña-a:6f277c97d4fc45a7972f8a5c9a3f97ab-2140:fe15a437-6618-4c05-8c8c-d31f409b2e96',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1e013271-b0df-4963-a76e-7d1ffab16557',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/bf082c8d-8820-4ee4-8d3a-aeac26518900.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'fb506171-8626-41a2-9327-09687899ac1b': {
        name: 'Pico Gallo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pico-gallo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-25c6:6288b7b7-c673-4ec9-b797-7b4f5e7ef172',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'fb506171-8626-41a2-9327-09687899ac1b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7e1e9062-df0b-4b10-ad87-aab10f1766cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '792e2244-a104-4aed-ae77-da4e40c6f67a': {
        name: 'Chorizo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'chorizo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-c5e8:8c43e9cd-c5e6-430e-b294-2a68bb994947',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '792e2244-a104-4aed-ae77-da4e40c6f67a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2016a4de-bf99-4e25-b469-30dfe01ba58d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '43e7e759-84ae-41fa-aa31-03916deaf120': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-5ee2:6e1a423c-69a0-41bb-bd49-45bc653b8314',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '43e7e759-84ae-41fa-aa31-03916deaf120',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f546733b-0a62-4b88-93cb-fccb02221236': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-a751:40794b59-5a3f-45d6-a283-ece6a31daa2c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f546733b-0a62-4b88-93cb-fccb02221236',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2d65ba8b-8aeb-4806-8011-be3219336d1b': {
        name: 'Cebolla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'cebolla-a:6f277c97d4fc45a7972f8a5c9a3f97ab-cc22:d426140a-dec8-4304-a789-984f4fa9c6a5',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2d65ba8b-8aeb-4806-8011-be3219336d1b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f1ea24e4-4bb7-4d68-a485-a8f68befa7a2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '459ecbb4-ae9f-4996-a38a-4efa202adb0d': {
        name: 'Pimiento Verde',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pimiento-verde-a:6f277c97d4fc45a7972f8a5c9a3f97ab-c7c4:210be68a-0d49-4c5c-9453-50f802dc0cc0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '459ecbb4-ae9f-4996-a38a-4efa202adb0d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2fb0501d-2e62-4ac9-91bc-4a35aee64862.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '93d7a9cd-ffd8-40df-a5dc-ed7bc1b36b3b': {
        name: 'Aceituna Negra',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'aceituna-negra-a:6f277c97d4fc45a7972f8a5c9a3f97ab-1bd1:6450befd-3293-4165-86c1-2d390ad335f2',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '93d7a9cd-ffd8-40df-a5dc-ed7bc1b36b3b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1b207bf0-24c9-44c4-9baf-385b39f1373e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '0716e624-9f5b-4b44-9816-2950eddbc050': {
        name: 'Carne de Puerco',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-puerco-a:6f277c97d4fc45a7972f8a5c9a3f97ab-d188:9bbe819a-baed-4bc8-8d26-a2fb1163f060',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '0716e624-9f5b-4b44-9816-2950eddbc050',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7325214e-c7a1-4433-96ad-b528f7683a2e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '6be4d2b3-3db8-41ed-b9f0-7a4a7db75f84': {
        name: 'Carne Res',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-res-a:6f277c97d4fc45a7972f8a5c9a3f97ab-9b1c:61270375-e500-4712-8685-ca2035284033',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '6be4d2b3-3db8-41ed-b9f0-7a4a7db75f84',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/fb2b68e9-1338-4ad3-9804-0b7af8c37d23.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'ebf21508-ebe9-4973-bac9-ec8fe7bb4758': {
        name: 'Salchicha',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'salchicha-a:6f277c97d4fc45a7972f8a5c9a3f97ab-0b3b:480f5772-3103-4021-a30c-bf2dbf1c5e9f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'ebf21508-ebe9-4973-bac9-ec8fe7bb4758',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/222e3fd5-964e-4114-811a-0be4cf9d988d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'c8a41d55-b6a5-4dfa-a86b-3cdeb6245beb': {
        name: 'Peperoni',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'peperoni-a:6f277c97d4fc45a7972f8a5c9a3f97ab-45e5:c6242cdb-be1a-4d5d-9be1-30227c7be11e',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c8a41d55-b6a5-4dfa-a86b-3cdeb6245beb',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/726eb78a-7786-4b81-a987-2be39c932fc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'cb9b298e-b019-4fa1-8241-6bc003ff8e38': {
        name: 'Tocino',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'tocino-a:6f277c97d4fc45a7972f8a5c9a3f97ab-19ba:18558277-71bf-4d3f-8f99-58f3f7b4eaa6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'cb9b298e-b019-4fa1-8241-6bc003ff8e38',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f9569644-ac53-4b3c-b564-2d8aa381c35c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2e449368-8fe4-4356-bcb4-33302887e178': {
        name: 'Jalapeno',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jalapeno-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6d0d:e3d45078-081f-4d62-b86f-5b471b547dea',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2e449368-8fe4-4356-bcb4-33302887e178',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a9e853c1-4f31-4e2b-a60f-f60abfcc94f5.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '050f98ba-b23a-4ee8-bf46-1c48684c0c2b': {
        name: 'Piña',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'piña-a:6f277c97d4fc45a7972f8a5c9a3f97ab-a73c:5d2c7e3d-6b13-4de0-a76a-34cb361560cf',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '050f98ba-b23a-4ee8-bf46-1c48684c0c2b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/bf082c8d-8820-4ee4-8d3a-aeac26518900.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '90b6bd77-eaea-4295-9817-88f988c658e1': {
        name: 'Pico de Gallo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pico-gallo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-f7ba:a87d696a-8b18-49dc-837e-58c09f826f94',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '90b6bd77-eaea-4295-9817-88f988c658e1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7e1e9062-df0b-4b10-ad87-aab10f1766cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1e693908-eb76-42ba-a95d-33758a5b9a33': {
        name: 'Chorizo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'chorizo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-cf85:92118375-7136-4b18-95ad-3fd51536a055',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1e693908-eb76-42ba-a95d-33758a5b9a33',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2016a4de-bf99-4e25-b469-30dfe01ba58d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b7b2f757-6f78-42c1-96a8-ae22cee2b8bb': {
        name: 'Tradicional Mediana',
        description: '',
        modifierGroupIds: [
          'eca40af4-d548-4490-856f-272ba3d4e984',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pepperoni-a:9e5663a0d5eb4376b2514330e22b9343-4ead:7528b15b-358b-4fc7-800a-171a331029df:280ad418-27c0-4c80-9d2d-650e8d2d6770',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b7b2f757-6f78-42c1-96a8-ae22cee2b8bb',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a5caef1b-5e38-456d-a816-89f078cea8cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'fdd21766-9454-47e5-8982-4177059c2275': {
        name: 'Hut Cheese Mediana',
        description: '',
        modifierGroupIds: [
          'eca40af4-d548-4490-856f-272ba3d4e984',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'hut-cheese-a:9e5663a0d5eb4376b2514330e22b9343-c807:c51a49b8-e780-457e-8703-cd3a2ab78aa1:f2cf56c0-a1aa-4f7f-b484-223332798d5c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'fdd21766-9454-47e5-8982-4177059c2275',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/c5348651-70ea-4e04-8e93-3ba8d8630670.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '549efa52-b074-4210-ab82-3013facb13e6': {
        name: 'Pan Pizza Mediana',
        description: '',
        modifierGroupIds: [
          'eca40af4-d548-4490-856f-272ba3d4e984',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'pan-pizza-a:9e5663a0d5eb4376b2514330e22b9343-34c8:f29edd94-1c95-4c17-b9a5-8941154a7c38:1dfee344-ec82-4841-9fb4-7999ded7c02f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '549efa52-b074-4210-ab82-3013facb13e6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '2c07eb31-e702-45ca-9a3b-0b2dff3c1714': {
        name: 'Crispy Mediana',
        description: '',
        modifierGroupIds: [
          'eca40af4-d548-4490-856f-272ba3d4e984',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'crispy-a:9e5663a0d5eb4376b2514330e22b9343-3600:40b885bb-e297-4b63-a2d3-264574402b16:87c79d76-a79f-48a7-b676-52472ab6ff66',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '2c07eb31-e702-45ca-9a3b-0b2dff3c1714',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/57144ff6-0613-4c1e-ba5d-16692734314f.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '930712d8-d30d-4921-99f1-d90906a99d93': {
        name: '1 Ingrediente Grande Tradicional',
        description: '',
        modifierGroupIds: [
          'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 30,
        },
        skuDetails: {
          skuSlug: 'tradicional-familiar-a:9e5663a0d5eb4376b2514330e22b9343-4b0a:41a5db62-5d5c-4158-85b7-8fb592305934:9f50bc74-60b9-4720-9790-8d98ebf09d01',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '930712d8-d30d-4921-99f1-d90906a99d93',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a5caef1b-5e38-456d-a816-89f078cea8cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b0e8ab78-9dd4-4675-824d-deed75be720c': {
        name: 'Cheesy Pops Grande',
        description: '',
        modifierGroupIds: [
          'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 70,
        },
        skuDetails: {
          skuSlug: 'cheesy-pops-familiar-a:9e5663a0d5eb4376b2514330e22b9343-ab2e:4e4efb7f-268d-4804-94cb-bf7361339eca:17cb15e4-5fa4-476a-a769-866398aadfc0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b0e8ab78-9dd4-4675-824d-deed75be720c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7384de1f-cefe-4ff2-bafa-466c81045d90.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '370e144b-8ffe-4bda-8bbf-d4e00bb66c85': {
        name: 'Hut Cheese Grande',
        description: '',
        modifierGroupIds: [
          'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 70,
        },
        skuDetails: {
          skuSlug: 'hut-cheese-familiar-a:9e5663a0d5eb4376b2514330e22b9343-b72a:7397f67d-e644-4fa7-b1b7-ce20c4ad7ce8:2834f308-b5b7-4367-977f-5ff0940f2d12',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '370e144b-8ffe-4bda-8bbf-d4e00bb66c85',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/c5348651-70ea-4e04-8e93-3ba8d8630670.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '69d79a41-98e2-4fed-acf9-70961694c0b0': {
        name: 'Pan Pizza Grande',
        description: '',
        modifierGroupIds: [
          'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 70,
        },
        skuDetails: {
          skuSlug: 'pan-pizza-familiar-a:9e5663a0d5eb4376b2514330e22b9343-b6ce:485bebc3-e49f-4dcc-b1f3-f24a7c54017e:bd2e8010-972f-475b-a953-32f849cbbb5d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '69d79a41-98e2-4fed-acf9-70961694c0b0',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '71546c3e-0f5c-4ea8-a3f0-2b996bd8100c': {
        name: 'Crispy Grande',
        description: '',
        modifierGroupIds: [
          'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 70,
        },
        skuDetails: {
          skuSlug: 'crispy-familiar-a:9e5663a0d5eb4376b2514330e22b9343-908e:dfba1700-b9dc-4a7d-afce-bd1120065bd0:24361608-bbed-463d-ba96-78ca602ef417',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '71546c3e-0f5c-4ea8-a3f0-2b996bd8100c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/57144ff6-0613-4c1e-ba5d-16692734314f.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1a3cc98a-f368-45d8-81fd-ff8b274ef66f': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6eff:bc14b6ec-d59d-4b16-9e32-a31ef0f1989a',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1a3cc98a-f368-45d8-81fd-ff8b274ef66f',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1fe31bde-f0c8-431a-9f1c-43c938358a4f': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-121b:09f52cbc-6250-4835-844e-ed5e9c424dc4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1fe31bde-f0c8-431a-9f1c-43c938358a4f',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'c85040c5-b949-425e-8d2d-76690651f6c6': {
        name: 'Cebolla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'cebolla-a:6f277c97d4fc45a7972f8a5c9a3f97ab-b993:97d9f2d9-6328-45f9-ba31-7f07094656f7',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c85040c5-b949-425e-8d2d-76690651f6c6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f1ea24e4-4bb7-4d68-a485-a8f68befa7a2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8d4aafbf-eda5-4454-a9af-edc25197cf15': {
        name: 'Pimiento Verde',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pimiento-verde-a:6f277c97d4fc45a7972f8a5c9a3f97ab-a1a7:40e71a55-713a-4c7f-a53a-f7559e7c877c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8d4aafbf-eda5-4454-a9af-edc25197cf15',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2fb0501d-2e62-4ac9-91bc-4a35aee64862.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a124e651-dd54-44fe-9eac-b59ac1c4c398': {
        name: 'Aceituna Negra',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'aceituna-negra-a:6f277c97d4fc45a7972f8a5c9a3f97ab-4bfb:d14a86e5-d8fd-4ec4-a39b-996a8ba53b74',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a124e651-dd54-44fe-9eac-b59ac1c4c398',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/1b207bf0-24c9-44c4-9baf-385b39f1373e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '46078f96-90ff-40cc-a582-19b3099c8fbc': {
        name: 'Carne de Puerco',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-puerco-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6110:ea65aa03-4620-4715-ade8-601a35181ab4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '46078f96-90ff-40cc-a582-19b3099c8fbc',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7325214e-c7a1-4433-96ad-b528f7683a2e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '725bbaef-afef-474c-8e48-73e9fee685bf': {
        name: 'Carne Res',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'carne-res-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6c65:2ab56490-63b7-43ef-86cb-fe3e5e205e3c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '725bbaef-afef-474c-8e48-73e9fee685bf',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/fb2b68e9-1338-4ad3-9804-0b7af8c37d23.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f6e9555f-d9e0-4eca-b557-7758262fc4af': {
        name: 'Salchicha',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'salchicha-a:6f277c97d4fc45a7972f8a5c9a3f97ab-f52e:340c4d57-f5ea-40ea-be2c-46d2e5ae4e49',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f6e9555f-d9e0-4eca-b557-7758262fc4af',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/222e3fd5-964e-4114-811a-0be4cf9d988d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a1777761-e596-4780-8025-6f8aa22e9f50': {
        name: 'Peperoni',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'peperoni-a:6f277c97d4fc45a7972f8a5c9a3f97ab-bd8f:8dd2203f-5310-4a48-9df9-bec4b595b1ae',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a1777761-e596-4780-8025-6f8aa22e9f50',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/726eb78a-7786-4b81-a987-2be39c932fc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '673cbcd4-ecdd-4b4f-8556-8f99d4233504': {
        name: 'Tocino',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'tocino-a:6f277c97d4fc45a7972f8a5c9a3f97ab-5786:a055aff1-0ff2-4bd5-a353-aeaaa780c53b',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '673cbcd4-ecdd-4b4f-8556-8f99d4233504',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f9569644-ac53-4b3c-b564-2d8aa381c35c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '590776b8-24cb-49bf-a81e-73b69fab3bfc': {
        name: 'Jalapeno',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jalapeno-a:6f277c97d4fc45a7972f8a5c9a3f97ab-11eb:fc187eba-a2be-4a50-a546-5170d55ba07a',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '590776b8-24cb-49bf-a81e-73b69fab3bfc',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a9e853c1-4f31-4e2b-a60f-f60abfcc94f5.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9dccc443-e3d6-4979-a439-9cb0ed8ad5d7': {
        name: 'Piña',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'piña-a:6f277c97d4fc45a7972f8a5c9a3f97ab-b6eb:6068bcfe-6b1d-4d44-9189-50419f43b99a',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9dccc443-e3d6-4979-a439-9cb0ed8ad5d7',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/bf082c8d-8820-4ee4-8d3a-aeac26518900.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '8d7f6e29-3dfd-4eb0-b497-8026de64ab0b': {
        name: 'Pico de Gallo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'pico-gallo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-0f58:4184a123-e6ed-4359-bf15-ab240913c9b3',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '8d7f6e29-3dfd-4eb0-b497-8026de64ab0b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7e1e9062-df0b-4b10-ad87-aab10f1766cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd7cd295a-4ede-4471-bfd6-293f4e1b4cb0': {
        name: 'Chorizo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'chorizo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-29d8:d9b519d1-6dc4-48d3-93bb-01467dccff90',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd7cd295a-4ede-4471-bfd6-293f4e1b4cb0',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/2016a4de-bf99-4e25-b469-30dfe01ba58d.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b02167c9-f140-4fc7-80dc-2db97b3cda6e': {
        name: 'Queso Extra Gran Hut',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 49,
        },
        skuDetails: {
          skuSlug: 'upfxtoi2q/cj2sqaz2k3na:queso-extra-gran-hut-a:byd8l9T8RaeXL4pcmj+Xqw:32f61252-00fe-4ee1-8a2b-6856351b7804',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b02167c9-f140-4fc7-80dc-2db97b3cda6e',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/80c2c52f-dac8-4f7f-ad7a-bab83fa1c19e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b0fedb6e-0f93-4384-b64b-72b40c5a531b': {
        name: '½ MASA Tradicional Basica',
        description: '',
        modifierGroupIds: [
          'fb34a2bd-31cf-4464-83d6-2607057691ae',
          '9f04cf6d-9424-460f-87d5-c9c099edb6c0',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'oyuznlyfrdoafpz45ivqtg:m/m-tradicional-mediana-basica-a:byd8l9T8RaeXL4pcmj+Xqw:962394c0-d30d-44e9-88fa-fa4c13a574aa',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b0fedb6e-0f93-4384-b64b-72b40c5a531b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a5caef1b-5e38-456d-a816-89f078cea8cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'e000f27a-e42c-4d5a-a156-b00abeb84b03': {
        name: '½ MASA Pan Basica',
        description: '',
        modifierGroupIds: [
          '46f9bfd7-b0d1-4d8f-aa28-6e21b4c8c8f7',
          '13bd065e-2ddd-4342-a0be-90bb7c6f0ac2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'if5s3uxjqdamgnockile5w:m/m-pan-med-basica-a:byd8l9T8RaeXL4pcmj+Xqw:3d6afd3a-5f99-4485-885a-19677db002bc',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'e000f27a-e42c-4d5a-a156-b00abeb84b03',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'c642ce42-1a9e-4526-8163-19a43a027114': {
        name: '½ MASA Crispy Basica',
        description: '',
        modifierGroupIds: [
          '98f33268-4d7e-47b8-802d-c74508d06415',
          'd36ef233-a1dd-4d46-9ca2-205465a86aed',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'ziid8e1wrza4lyyh/3nw:m/m-cri-med-basica-a:byd8l9T8RaeXL4pcmj+Xqw:3cabb3f9-4676-4ff5-a0f8-7e450e25f6b0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c642ce42-1a9e-4526-8163-19a43a027114',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/57144ff6-0613-4c1e-ba5d-16692734314f.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3d0d77f1-12e0-4347-877c-c3a6a5df38aa': {
        name: '½ MASA Tradicional Basica G',
        description: '',
        modifierGroupIds: [
          '4dd3ce10-4fd8-4fff-b673-0a2a19a42bfc',
          '91a71a37-0868-4b89-a8dc-7afabad5bb4b',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'mhh4jgxrsvs9paqdxjodxq:m/m-tradicional-familiar-basica-a:byd8l9T8RaeXL4pcmj+Xqw:6780cb1b-7908-4a3c-86ec-ae15d473ac9f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3d0d77f1-12e0-4347-877c-c3a6a5df38aa',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/a5caef1b-5e38-456d-a816-89f078cea8cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '10b256e4-216d-4890-a517-9aa68b2bba33': {
        name: '½ MASA Pan Basico G',
        description: '',
        modifierGroupIds: [
          '3a693e67-beb2-4f10-9541-d50294d193c8',
          '5a58993e-b58f-4b7b-91d4-2effaf3860d2',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'etrdqqtfa5yz9mgdzikw:m/m-pan-fam-basica-a:byd8l9T8RaeXL4pcmj+Xqw:b5fcfda1-b09a-41c8-aa2f-fd48e1af4e9d',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '10b256e4-216d-4890-a517-9aa68b2bba33',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/909c95e2-ab29-4743-b6e1-3761f01a0079.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '7d74f9d6-1209-4f3a-98bb-09364d73567b': {
        name: '½ MASA Crispy Basica G',
        description: '',
        modifierGroupIds: [
          'a179346a-edeb-44b5-9396-783e4252b234',
          'e439347d-8ace-4823-b7a7-7a81afadfb74',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 40,
        },
        skuDetails: {
          skuSlug: 'nqbnh6ips3ktcvoqn9ra7a:m/m-cri-fam-basica-a:byd8l9T8RaeXL4pcmj+Xqw:ee230b87-e875-403b-b281-b73ea5e8fd35',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '7d74f9d6-1209-4f3a-98bb-09364d73567b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/57144ff6-0613-4c1e-ba5d-16692734314f.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '1292eca0-e2bb-4b72-a0f6-4d4bdb76b538': {
        name: '½ Gran Hut Queso',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '4dl6pzghswm9tmurnlfllq:1/2-granhut-queso-a:byd8l9T8RaeXL4pcmj+Xqw:0c5df004-a9e1-4d22-b756-4be03576b4fc',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '1292eca0-e2bb-4b72-a0f6-4d4bdb76b538',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/d2b91d2a-7dbf-4309-a5a1-f437b5099cf2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '43adf6ce-8879-49ee-b1b8-30421006519a': {
        name: '½ Gran Hut 1 Ingrediente',
        description: '',
        modifierGroupIds: [
          '08acdb19-de79-4c52-8b33-c3fa1bc48286',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'qzq2q3err42srmx8ty6dqg:1/2-granhut-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:da68b739-277f-4f18-a87e-b3ce8ada56d6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '43adf6ce-8879-49ee-b1b8-30421006519a',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/d2b91d2a-7dbf-4309-a5a1-f437b5099cf2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3996e86f-c08b-47ce-a180-d533106fff70': {
        name: 'SIN HIELO',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'b22a6217-a419-4b84-aa2b-3e03da9beb3f:75b60a08-2a95-4088-9cff-887306758764:c9a571d0-0417-4f2a-944d-7117554874e3',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3996e86f-c08b-47ce-a180-d533106fff70',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/6d204920-cae0-4f9e-bf71-e8cf6016ac97.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'd7174c6d-bbcd-4ada-8de6-620b44bad27d': {
        name: 'Queso Extra Personal',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 19,
        },
        skuDetails: {
          skuSlug: 'pvy3l2bhqn2bidyotwa2ga:queso-extra-personal-a:byd8l9T8RaeXL4pcmj+Xqw:f7b69661-af6e-448f-a649-5081b3dbeaff',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'd7174c6d-bbcd-4ada-8de6-620b44bad27d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/80c2c52f-dac8-4f7f-ad7a-bab83fa1c19e.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '7e4776ed-c3cb-4356-ab42-044fc53062e2': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-6e1e:7a40cf55-4df5-4c1f-a947-0059b3e66421',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '7e4776ed-c3cb-4356-ab42-044fc53062e2',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a00d804d-7336-4a4f-8d26-609a04aaad07': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-29f7:9011f3ee-3886-44de-9aa9-ceaec027b85a',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a00d804d-7336-4a4f-8d26-609a04aaad07',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'c1d08b93-d2d1-4b6f-90cd-819ec8dfd749': {
        name: 'Cebolla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'cebolla-a:6f277c97d4fc45a7972f8a5c9a3f97ab-0ef0:e32e34d1-4141-4ea4-a2f7-fc8d9c67f4e8',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'c1d08b93-d2d1-4b6f-90cd-819ec8dfd749',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f1ea24e4-4bb7-4d68-a485-a8f68befa7a2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '002d96b4-e7a6-4391-a53c-aac9203185d8': {
        name: 'Peperoni',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'peperoni-a:6f277c97d4fc45a7972f8a5c9a3f97ab-047e:7eb7f602-81dc-4f9f-b148-3779dd8ac1c4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '002d96b4-e7a6-4391-a53c-aac9203185d8',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/726eb78a-7786-4b81-a987-2be39c932fc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '91c032ec-0051-4442-8d04-8fc6f95e2fd6': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-fbda:096be9ba-7ad3-47bc-84b5-fb08e3c56121',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '91c032ec-0051-4442-8d04-8fc6f95e2fd6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9567d898-dfb9-428d-b52c-2c833925c28c': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-4953:f33b061e-989d-4e9a-8b75-dfdfd9fc0328',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9567d898-dfb9-428d-b52c-2c833925c28c',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '09486a35-0cf3-4c1a-94a8-dcd2e5cc06ad': {
        name: '½ Tradicional Queso (Mediana)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'rapd6/zvrveh/ku2bi/qa:1/2-med-tra-queso-a:byd8l9T8RaeXL4pcmj+Xqw:77f935d4-75b2-4e49-8c9f-fed07a342c05',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '09486a35-0cf3-4c1a-94a8-dcd2e5cc06ad',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f3eefb45-797e-40fb-87b3-08acbf9e85ed': {
        name: '½ Tradicional 1 Ingrediente (Mediana)',
        description: '',
        modifierGroupIds: [
          'a00dae9e-3489-48fa-855d-dee8f7195c80',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'wwfxonemrmiphsxgbfmsnq:1/2-med-tra-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:f3af9c02-a17b-4a6c-a54b-edec7e7a592e',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f3eefb45-797e-40fb-87b3-08acbf9e85ed',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'a7904578-c9db-48a0-9210-fe50643c6e70': {
        name: '½ Pan Queso (Mediana)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'akqz0iiur0kyqshrllhzhq:1/2-med-pan-queso-a:byd8l9T8RaeXL4pcmj+Xqw:f33ea183-f0c4-4018-9d91-9025a370caf4',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'a7904578-c9db-48a0-9210-fe50643c6e70',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b258ed19-14aa-4c07-8996-86d3bf032738': {
        name: '½ Pan 1 Ingrediente (Mediana)',
        description: '',
        modifierGroupIds: [
          'a00dae9e-3489-48fa-855d-dee8f7195c80',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'wxwtamjjt/dotlje/awiq:1/2-med-pan-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:2eef7081-adb7-4f65-993d-12dbde0d1706',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b258ed19-14aa-4c07-8996-86d3bf032738',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f5e581fd-17b2-43ba-b742-e59f32d7dca1': {
        name: '½ Crispy Queso (Mediana)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '3sz51jeurqu8byzhxxofgw:1/2-med-cri-queso-a:byd8l9T8RaeXL4pcmj+Xqw:1fbec3de-80cf-438f-954e-9effb3a67e1b',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f5e581fd-17b2-43ba-b742-e59f32d7dca1',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '9a66c4be-29b0-49d8-a79d-f8525a3bf398': {
        name: '½ Crispy 1 Ingrediente (Mediana)',
        description: '',
        modifierGroupIds: [
          'a00dae9e-3489-48fa-855d-dee8f7195c80',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'urqt6oofsvgf/bhi1gy9a:1/2-med-cri-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:4ccbf256-84c1-48fd-90da-61421724f234',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '9a66c4be-29b0-49d8-a79d-f8525a3bf398',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '3307bbc4-ad8d-4d6f-b67d-e983a237566d': {
        name: '½ Tradicional Queso (Grande)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'k6q30ujzqagbiulrvwkc7q:1/2-fam-tra-queso-a:byd8l9T8RaeXL4pcmj+Xqw:2272e513-3ee2-4fe1-9fcc-1f5cc98f9578',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '3307bbc4-ad8d-4d6f-b67d-e983a237566d',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '0e7ad22d-e1a4-4dee-9571-9b570264a68b': {
        name: '½ Tradicional 1 Ingrediente (Grande)',
        description: '',
        modifierGroupIds: [
          '484e119f-3ed1-4f8b-bead-9c434ec8ae6b',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'hf8kcp6cqinpbunny7kta:1/2-fam-tra-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:b14fda8c-9274-416f-87d8-35b320b7c9a0',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '0e7ad22d-e1a4-4dee-9571-9b570264a68b',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '28ef8657-2aec-4004-b6e1-4ddc255ef448': {
        name: '½ Pan Queso (Grande)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'acd8sdnmtx2vf/tmy9frew:1/2-fam-pan-queso-a:byd8l9T8RaeXL4pcmj+Xqw:de8c0268-915f-4746-912b-56be0888ab2a',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '28ef8657-2aec-4004-b6e1-4ddc255ef448',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'e6d8901e-cbeb-48ed-8014-46e33631aee6': {
        name: '½ Pan 1 Ingrediente (Grande)',
        description: '',
        modifierGroupIds: [
          '484e119f-3ed1-4f8b-bead-9c434ec8ae6b',
        ],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'kbuano2pqvqscipvowvqda:1/2-fam-pan-1-ing-a:byd8l9T8RaeXL4pcmj+Xqw:50a87b90-e0af-4631-99c1-bbf02c9743b6',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'e6d8901e-cbeb-48ed-8014-46e33631aee6',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'f95ec718-a763-43dc-8391-cb1d3693ed0e': {
        name: '½ Crispy Queso (Grande)',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'mhzhfjctmgezravyczhha:1/2-fam-cri-queso-a:byd8l9T8RaeXL4pcmj+Xqw:2e23a2ed-cf5e-4459-a697-c2ef544bf29f',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'f95ec718-a763-43dc-8391-cb1d3693ed0e',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ce7dc9e2-6e7f-4b84-acf4-4142feb9f37c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'cf974dc0-92a2-4450-8a7c-58eea67ce2db': {
        name: 'Pepperoni',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 't04i4yfkrtldvsmuuv/aa:1/2-pepperoni-a:byd8l9T8RaeXL4pcmj+Xqw:45ae57d9-b6ff-4f30-a1b4-e3cf34761737',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'cf974dc0-92a2-4450-8a7c-58eea67ce2db',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/726eb78a-7786-4b81-a987-2be39c932fc7.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '82bfe8b2-c965-4078-8700-2c346faa6525': {
        name: 'Tocino',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'gahjty8bsvq9wjtaypgx6q:1/2-tocino-a:byd8l9T8RaeXL4pcmj+Xqw:16bb46d2-28d4-471f-9769-72572d038b2c',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '82bfe8b2-c965-4078-8700-2c346faa6525',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f9569644-ac53-4b3c-b564-2d8aa381c35c.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'ae0a16a1-697f-40d5-b582-c6ea79d5c135': {
        name: 'Rajas',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'ofsrha0iriwj1fsdvt3jla:1/2-rajas-a:byd8l9T8RaeXL4pcmj+Xqw:f45546cd-2604-4f5b-886f-30ec1ee582cf',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'ae0a16a1-697f-40d5-b582-c6ea79d5c135',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/039592b2-9b80-46c5-ad08-1698cfbe6156.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '4d31ba93-261d-4efd-a180-a171824d0364': {
        name: 'Jamon',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '½-jamon-a:6f277c97d4fc45a7972f8a5c9a3f97ab-8dc4:80365fe9-5a8c-4ae8-a727-770271053eaf',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '4d31ba93-261d-4efd-a180-a171824d0364',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/ee73865a-d682-4286-b153-3ef9f55fba29.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '7f1480da-2363-4b7a-87a6-f3d7fd842253': {
        name: 'Champiñones',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '½-champiñones-a:6f277c97d4fc45a7972f8a5c9a3f97ab-1367:e8f305bd-8022-4416-80d6-d1d212492a91',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '7f1480da-2363-4b7a-87a6-f3d7fd842253',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/9e79c079-e62f-4e0d-aabe-ee423b7dca96.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'b27fd92b-b184-41cc-bef7-7581b7426216': {
        name: 'Cebolla',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '½-cebolla-a:6f277c97d4fc45a7972f8a5c9a3f97ab-af07:1a34fdf0-3918-47b9-bda3-6eaf156ff3ee',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'b27fd92b-b184-41cc-bef7-7581b7426216',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/f1ea24e4-4bb7-4d68-a485-a8f68befa7a2.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'e6fa999a-444b-4737-b153-4e6434f16310': {
        name: 'Queso',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: 'kna0kwbrj6m4lcxtxm61q:1/2-queso-a:byd8l9T8RaeXL4pcmj+Xqw:af157ef6-6f73-4ea2-849d-1394f2fa7a91',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'e6fa999a-444b-4737-b153-4e6434f16310',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/6661b986-c5a4-4791-a116-784f55b91de8.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      '814b3bc7-023a-4879-92b6-61b3f36e1058': {
        name: 'Pico de Gallo',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '½-pico-gallo-a:6f277c97d4fc45a7972f8a5c9a3f97ab-849e:c88bfcec-3c71-4494-a7be-f4762a3ff522',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: '814b3bc7-023a-4879-92b6-61b3f36e1058',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/7e1e9062-df0b-4b10-ad87-aab10f1766cc.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
      'ecccb8cb-8ba2-4006-aaa8-020d7d74da28': {
        name: 'Piña',
        description: '',
        modifierGroupIds: [],
        status: {
          saleStatus: 'FOR_SALE',
        },
        price: {
          currencyCode: 'MXN',
          amount: 0,
        },
        skuDetails: {
          skuSlug: '½-piña-a:6f277c97d4fc45a7972f8a5c9a3f97ab-b6ac:75181cb5-c104-47cb-9da7-ea44eb9bf13b',
          barcodes: [],
          dietaryClassifications: [],
          allergenClassifications: [],
          additives: [],
          storageRequirements: [],
          containsAlcohol: false,
        },
        additionalCharges: [],
        id: 'ecccb8cb-8ba2-4006-aaa8-020d7d74da28',
        photoIds: [
          'https://photos.tryotter.com/menu-photos/bf082c8d-8820-4ee4-8d3a-aeac26518900.png',
        ],
        priceOverrides: [],
        tax: {
          percentageValue: {
            decimalValue: 0,
          },
          isValueAddedTax: true,
        },
      },
    },
    modifierGroups: {
      '686cae9d-d25e-4a9a-8297-c0db5a714af0': {
        name: 'HAMBURGUESA KRUNCHER',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'f7630eb6-9775-4f4f-965b-746f0b2386a2',
        ],
        id: '686cae9d-d25e-4a9a-8297-c0db5a714af0',
      },
      '23fed109-20e5-4cc2-a180-2f81d99cecd8': {
        name: 'ELIGE COMPLEMENTOS COMBO',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '779ee5da-b019-4b76-a21f-75888e5f7f46',
          '7911d8f6-97d7-4f37-ab8c-34e1a1efbe8d',
          '8a49e398-2be7-40a5-830e-3563ae0f8f93',
          '0ae1358c-1337-45f0-870f-6eb5a7e35eeb',
        ],
        id: '23fed109-20e5-4cc2-a180-2f81d99cecd8',
      },
      '1b081087-e3d8-44c6-a6e3-2d02eb37843f': {
        name: '+AGREGA UN ANTOJO',
        minimumSelections: 0,
        maximumSelections: 20,
        maxPerModifierSelectionQuantity: 20,
        itemIds: [
          'd5c7b61a-10ec-4ece-8666-9dec193010dd',
          'd848819a-c6af-4b27-8986-ece7a3b3cd17',
          '86d7fa21-c15d-4afb-9211-e45dfb7be8a4',
          'a0da476d-eceb-49ad-8a46-ae00619bc52f',
          'da922447-4e67-4370-b1e4-69c4d20dd327',
          '39d2d735-f5bf-4d8b-b297-753dc6ac613a',
          'ef64ab4b-9c6b-4a6c-9817-6e7b0b81a17c',
          'aa2fa685-885b-4f17-ad88-97428aa9f7d3',
          '9b668e02-76da-4bc5-aebb-5e17c29a8cca',
        ],
        id: '1b081087-e3d8-44c6-a6e3-2d02eb37843f',
      },
      'eda8a7c0-d96a-4c29-98ee-bfa73b6dc27f': {
        name: '¿Cómo prefieres tu producto?',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3547889a-4a8d-4506-8148-8be24ebf6985',
          'f91e1aa2-98a8-4af3-8aae-d1a3bf88b5e1',
        ],
        id: 'eda8a7c0-d96a-4c29-98ee-bfa73b6dc27f',
      },
      'aa6cfffc-574b-4b53-b329-23127b430910': {
        name: 'Tamaño de tu bebida',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '4eb4f029-daa4-4501-a105-421d5cf6d9a8',
          '9203e654-1295-4df5-8fcc-f9d8af291f02',
        ],
        id: 'aa6cfffc-574b-4b53-b329-23127b430910',
      },
      '550b413b-5332-4f50-b41d-5f5f6b2ed53b': {
        name: '¿Qué café prefieres?',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '390befda-5076-4eb8-95cf-91f2991985fe',
          '6aa6f69e-4b26-4ad7-9177-1292c87d8cab',
        ],
        id: '550b413b-5332-4f50-b41d-5f5f6b2ed53b',
      },
      'a8a4610c-7a5c-46aa-acda-a30924f36f7a': {
        name: 'Crema chantilly',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '199cd2d3-3413-4e94-a596-b21dd38e1ea3',
          'e30dee65-5ada-43dd-9211-b703bb8e9564',
        ],
        id: 'a8a4610c-7a5c-46aa-acda-a30924f36f7a',
      },
      'd1a5364c-6770-491b-96a8-a872839ea766': {
        name: '¿Adiciones para tu bebida?',
        minimumSelections: 0,
        maximumSelections: 8,
        maxPerModifierSelectionQuantity: 8,
        itemIds: [
          '492ba2d9-c2d4-45a5-a801-300611531cf6',
          '5ef0f430-f81e-484b-9ea9-14e1e5bdb524',
          'f265e693-7f4c-4bea-9a6c-03ec234e23c1',
          '4500f833-2f29-44cf-9be7-27ba24d798cf',
          '5973d475-c085-4b69-9f49-177813132413',
          'c3669c46-40b4-47d3-9475-e3a3165bb3f0',
          '8895ce2a-c8c2-4dff-9ff9-84a709a69113',
          'ca32fea2-f60d-4419-981f-d5d24f909d6c',
        ],
        id: 'd1a5364c-6770-491b-96a8-a872839ea766',
      },
      'b59b67ad-a928-4c77-a5ce-1744171a10bb': {
        name: 'ELIGE TU ESPECIALIDAD O ARMA TU PIZZA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '5e9fdd0f-c57f-4b3d-8bfe-497ce6733ca2',
          '0d242845-2a51-4867-a570-54ad11ec35fc',
          '8757bc47-fd91-42b4-9375-3329b4acfe60',
        ],
        id: 'b59b67ad-a928-4c77-a5ce-1744171a10bb',
      },
      '2e10346b-88d6-4eb3-a6d6-681b793e034f': {
        name: 'AGREGA COMPLEMENTO O BEBIDA EXTRA',
        minimumSelections: 0,
        maximumSelections: 99,
        maxPerModifierSelectionQuantity: 99,
        itemIds: [
          '8148ac5e-9237-45b5-87f6-3c2bc069dc16',
          'b624db0c-eb55-4b24-a45f-a8afc59d9f93',
          '368e01b8-61a0-4b01-8b0f-97d2911304a4',
          '464c796c-1f46-4e1f-bfb4-386a59763592',
          '014b0d35-3016-4fda-b446-a68989e560b1',
          '76da3f6b-ff80-4faa-b699-c1d0b61da5b3',
        ],
        id: '2e10346b-88d6-4eb3-a6d6-681b793e034f',
      },
      '238a5ecb-824a-4eac-8fe4-7aec3966dcfa': {
        name: 'SELECCIONA EL TAMAÑO Y TIPO DE MASA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'fb0d5284-40b8-4c5f-8aff-57651fcf2692',
          '5ff3993c-5e28-44d7-9be7-36e4921a5131',
          '6c9acd50-73e7-4e27-a839-c40097cf9472',
        ],
        id: '238a5ecb-824a-4eac-8fe4-7aec3966dcfa',
      },
      '9e1c8e0b-1014-4335-8f1b-84cee4d30d2e': {
        name: '[DO NOT SHOW] Upgrade QUEPAPAS',
        minimumSelections: 0,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '43a21b35-35f8-491b-8b5e-0c350cbe6ca6',
        ],
        id: '9e1c8e0b-1014-4335-8f1b-84cee4d30d2e',
      },
      '8d62b4f8-4732-4e2b-bc2e-2149757cacf1': {
        name: 'ELIGE EL TAMAÑO ½ BASICA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'fa722044-75e3-4624-b036-a9ee8fd17dcb',
          '05cb0e96-7e65-4baa-9b82-a576fef312f4',
          'c04cfef8-5eae-4fce-b95c-4d2dd7b7ad53',
        ],
        id: '8d62b4f8-4732-4e2b-bc2e-2149757cacf1',
      },
      'b59d354e-7c76-487b-a50d-d9777d302c6c': {
        name: '[DO NOT SHOW] Upgrade COCA-COLA',
        minimumSelections: 0,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3101d752-e80f-41f7-a1ce-952aa3e78d32',
        ],
        id: 'b59d354e-7c76-487b-a50d-d9777d302c6c',
      },
      'daa03631-4e8e-4b42-b715-27503da2f823': {
        name: 'ELIGE TU JUGO',
        minimumSelections: 1,
        maximumSelections: 99,
        maxPerModifierSelectionQuantity: 99,
        itemIds: [
          'f9027666-61ee-4416-a931-a81d0ce1db2d',
          '2a80062c-40d2-47b2-9c9d-c27ef15caa15',
        ],
        id: 'daa03631-4e8e-4b42-b715-27503da2f823',
      },
      'ea9442ce-4207-4231-b245-2858a9b6d1b6': {
        name: 'PERSONALIZA TU HAMBURGUESA',
        minimumSelections: 0,
        maximumSelections: 4,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'd40ffeb7-9d21-48e3-b7e7-d439a1ae99d9',
          '3e688119-9908-495a-b5b5-fd3667b0834c',
          'be3390dd-e3bb-4008-acad-d1865b5c23ea',
          'd34402dc-e62a-4b41-b10e-00df337c8483',
        ],
        id: 'ea9442ce-4207-4231-b245-2858a9b6d1b6',
      },
      '23e70d4c-5db9-4145-a7bb-f91dff048a9a': {
        name: 'ELIGE REFRESCO 473 ML',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '2937a434-c359-4968-8625-4a2bd5d663c0',
          '1af2f402-8fb7-464d-9ef2-7a480937aeb5',
          '71e67412-3344-4e9f-a3f6-d8d4cf432e34',
          '8d338e22-197a-4626-9dc8-bb4e51ecc5b9',
          'd0f59534-81df-4fed-9a1d-6586d3283388',
          '9eb86e24-624f-4496-8e3f-2028003ac767',
        ],
        id: '23e70d4c-5db9-4145-a7bb-f91dff048a9a',
      },
      '8eaf170f-5fe6-4fe7-9325-58acf06838f0': {
        name: 'ESPECIALIDAD',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '2382ccfb-9417-4ce3-9851-a8e7890dff78',
          'd7cfc8e6-28b7-40d6-9038-66f999938477',
          '3b505ff8-a09a-492b-ac25-3b976db87018',
          '36429f15-1c93-48ac-a546-55e2b2314f7d',
          '96e93beb-0ee9-41df-b966-cbfad22ed31a',
          '6544ad72-8362-4cb8-be6e-8ab08589748f',
          '9bedb971-b123-45a6-af17-2a2503f7b7d0',
          '89a3b78f-c118-4e3b-98e9-da84bd8a16b2',
          '952b4cc9-4a16-448d-8cd6-6af15d21610a',
          '96f130d2-d346-4c1e-8578-708effdebc78',
        ],
        id: '8eaf170f-5fe6-4fe7-9325-58acf06838f0',
      },
      '89c20802-5d6d-4919-aadf-32a6627f8178': {
        name: 'ELIGE 1 INGREDIENTE PERSONAL',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'efb3d5b7-8d49-42cd-84aa-edbb270ba9a5',
          'dd5d1879-c864-4242-ab96-5b279d436205',
          '8116775c-5c83-46ac-86fb-8fc6cf099e86',
          'b2b4dfd3-3670-45bc-8489-a75bfef323d6',
          'cf457f62-4c17-4685-a21c-414fd4ae93a2',
          '12f2f3c8-1414-4318-afa8-411463c6131a',
          '13af2940-d809-4bc7-a674-db1bccaa6e51',
          '01f1d6fa-81f1-44a1-aa11-76f91fb382c6',
          '2b78edfa-cc65-4d9d-9bcd-ad04ace5b9aa',
          'd40fef97-74c9-46a3-a032-5a0421c161b1',
          'b10016ec-5870-4db8-96aa-ba09e465b4d8',
          '1e013271-b0df-4963-a76e-7d1ffab16557',
          'fb506171-8626-41a2-9327-09687899ac1b',
          '792e2244-a104-4aed-ae77-da4e40c6f67a',
        ],
        id: '89c20802-5d6d-4919-aadf-32a6627f8178',
      },
      '4a68ffdb-04a9-4299-a811-e26e6cbc316c': {
        name: 'ELIGE 2-4 INGREDIENTES PERSONAL',
        minimumSelections: 2,
        maximumSelections: 4,
        maxPerModifierSelectionQuantity: 4,
        itemIds: [
          '43e7e759-84ae-41fa-aa31-03916deaf120',
          'f546733b-0a62-4b88-93cb-fccb02221236',
          '2d65ba8b-8aeb-4806-8011-be3219336d1b',
          '459ecbb4-ae9f-4996-a38a-4efa202adb0d',
          '93d7a9cd-ffd8-40df-a5dc-ed7bc1b36b3b',
          '0716e624-9f5b-4b44-9816-2950eddbc050',
          '6be4d2b3-3db8-41ed-b9f0-7a4a7db75f84',
          'ebf21508-ebe9-4973-bac9-ec8fe7bb4758',
          'c8a41d55-b6a5-4dfa-a86b-3cdeb6245beb',
          'cb9b298e-b019-4fa1-8241-6bc003ff8e38',
          '2e449368-8fe4-4356-bcb4-33302887e178',
          '050f98ba-b23a-4ee8-bf46-1c48684c0c2b',
          '90b6bd77-eaea-4295-9817-88f988c658e1',
          '1e693908-eb76-42ba-a95d-33758a5b9a33',
        ],
        id: '4a68ffdb-04a9-4299-a811-e26e6cbc316c',
      },
      '52dffa7f-4f1e-41d5-ba17-326df1751d4e': {
        name: 'ELIGE TU MASA Y TU INGREDIENTE FAVORITO',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'b7b2f757-6f78-42c1-96a8-ae22cee2b8bb',
          'fdd21766-9454-47e5-8982-4177059c2275',
          '549efa52-b074-4210-ab82-3013facb13e6',
          '2c07eb31-e702-45ca-9a3b-0b2dff3c1714',
        ],
        id: '52dffa7f-4f1e-41d5-ba17-326df1751d4e',
      },
      '033cf4a4-ef17-468e-8995-d11bb0b0e455': {
        name: 'ELIGE TU MASA Y TU INGREDIENTE FAVORITO',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '930712d8-d30d-4921-99f1-d90906a99d93',
          'b0e8ab78-9dd4-4675-824d-deed75be720c',
          '370e144b-8ffe-4bda-8bbf-d4e00bb66c85',
          '69d79a41-98e2-4fed-acf9-70961694c0b0',
          '71546c3e-0f5c-4ea8-a3f0-2b996bd8100c',
        ],
        id: '033cf4a4-ef17-468e-8995-d11bb0b0e455',
      },
      '0208352b-a37e-4492-98f5-cca63adc89d3': {
        name: 'ELIGE 1 INGREDIENTE GRAN HUT',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '1a3cc98a-f368-45d8-81fd-ff8b274ef66f',
          '1fe31bde-f0c8-431a-9f1c-43c938358a4f',
          'c85040c5-b949-425e-8d2d-76690651f6c6',
          '8d4aafbf-eda5-4454-a9af-edc25197cf15',
          'a124e651-dd54-44fe-9eac-b59ac1c4c398',
          '46078f96-90ff-40cc-a582-19b3099c8fbc',
          '725bbaef-afef-474c-8e48-73e9fee685bf',
          'f6e9555f-d9e0-4eca-b557-7758262fc4af',
          'a1777761-e596-4780-8025-6f8aa22e9f50',
          '673cbcd4-ecdd-4b4f-8556-8f99d4233504',
          '590776b8-24cb-49bf-a81e-73b69fab3bfc',
          '9dccc443-e3d6-4979-a439-9cb0ed8ad5d7',
          '8d7f6e29-3dfd-4eb0-b497-8026de64ab0b',
          'd7cd295a-4ede-4471-bfd6-293f4e1b4cb0',
        ],
        id: '0208352b-a37e-4492-98f5-cca63adc89d3',
      },
      'bedfe1ac-14cd-44db-89ed-58ef3aff1190': {
        name: 'QUESO EXTRA GRAN HUT',
        minimumSelections: 0,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'b02167c9-f140-4fc7-80dc-2db97b3cda6e',
        ],
        id: 'bedfe1ac-14cd-44db-89ed-58ef3aff1190',
      },
      '887e3f5f-b1ea-44a5-ae3b-9925b66fe69c': {
        name: 'TIPO DE MASA ½ BASICA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'b0fedb6e-0f93-4384-b64b-72b40c5a531b',
          'e000f27a-e42c-4d5a-a156-b00abeb84b03',
          'c642ce42-1a9e-4526-8163-19a43a027114',
        ],
        id: '887e3f5f-b1ea-44a5-ae3b-9925b66fe69c',
      },
      '987a55ad-7f15-4c1f-9060-130691aa9809': {
        name: 'TIPO DE MASA ½ BASICA G',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3d0d77f1-12e0-4347-877c-c3a6a5df38aa',
          '10b256e4-216d-4890-a517-9aa68b2bba33',
          '7d74f9d6-1209-4f3a-98bb-09364d73567b',
        ],
        id: '987a55ad-7f15-4c1f-9060-130691aa9809',
      },
      'e7d23a0e-35a1-468f-bf45-f08733c68690': {
        name: '½ GRAN HUT (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '1292eca0-e2bb-4b72-a0f6-4d4bdb76b538',
          '43adf6ce-8879-49ee-b1b8-30421006519a',
        ],
        id: 'e7d23a0e-35a1-468f-bf45-f08733c68690',
      },
      '137ee522-df78-44c9-b5b5-260c8cfd0627': {
        name: '½ GRAN HUT (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '1292eca0-e2bb-4b72-a0f6-4d4bdb76b538',
          '43adf6ce-8879-49ee-b1b8-30421006519a',
        ],
        id: '137ee522-df78-44c9-b5b5-260c8cfd0627',
      },
      '21e7cca7-ff64-479b-8031-6796ef6507e2': {
        name: 'PERSONALIZA TU BEBIDA',
        minimumSelections: 0,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3996e86f-c08b-47ce-a180-d533106fff70',
        ],
        id: '21e7cca7-ff64-479b-8031-6796ef6507e2',
      },
      '3d5478a0-ddd4-4852-86f4-88b69101c051': {
        name: 'QUESO EXTRA PERSONAL',
        minimumSelections: 0,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'd7174c6d-bbcd-4ada-8de6-620b44bad27d',
        ],
        id: '3d5478a0-ddd4-4852-86f4-88b69101c051',
      },
      'eca40af4-d548-4490-856f-272ba3d4e984': {
        name: 'ELIGE 1 INGREDIENTE MEDIANA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '7e4776ed-c3cb-4356-ab42-044fc53062e2',
          'a00d804d-7336-4a4f-8d26-609a04aaad07',
          'c1d08b93-d2d1-4b6f-90cd-819ec8dfd749',
        ],
        id: 'eca40af4-d548-4490-856f-272ba3d4e984',
      },
      'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef': {
        name: 'ELIGE 1 INGREDIENTE GRANDE',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '002d96b4-e7a6-4391-a53c-aac9203185d8',
          '91c032ec-0051-4442-8d04-8fc6f95e2fd6',
          '9567d898-dfb9-428d-b52c-2c833925c28c',
        ],
        id: 'c02d689c-8e72-4a6e-9bea-2cd0d5fdb9ef',
      },
      'fb34a2bd-31cf-4464-83d6-2607057691ae': {
        name: '½ TRADICIONAL (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '09486a35-0cf3-4c1a-94a8-dcd2e5cc06ad',
          'f3eefb45-797e-40fb-87b3-08acbf9e85ed',
        ],
        id: 'fb34a2bd-31cf-4464-83d6-2607057691ae',
      },
      '9f04cf6d-9424-460f-87d5-c9c099edb6c0': {
        name: '½ TRADICIONAL (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '09486a35-0cf3-4c1a-94a8-dcd2e5cc06ad',
          'f3eefb45-797e-40fb-87b3-08acbf9e85ed',
        ],
        id: '9f04cf6d-9424-460f-87d5-c9c099edb6c0',
      },
      '46f9bfd7-b0d1-4d8f-aa28-6e21b4c8c8f7': {
        name: '½ PAN PIZZA (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'a7904578-c9db-48a0-9210-fe50643c6e70',
          'b258ed19-14aa-4c07-8996-86d3bf032738',
        ],
        id: '46f9bfd7-b0d1-4d8f-aa28-6e21b4c8c8f7',
      },
      '13bd065e-2ddd-4342-a0be-90bb7c6f0ac2': {
        name: '½ PAN PIZZA (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'a7904578-c9db-48a0-9210-fe50643c6e70',
          'b258ed19-14aa-4c07-8996-86d3bf032738',
        ],
        id: '13bd065e-2ddd-4342-a0be-90bb7c6f0ac2',
      },
      '98f33268-4d7e-47b8-802d-c74508d06415': {
        name: '½ CRISPY (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'f5e581fd-17b2-43ba-b742-e59f32d7dca1',
          '9a66c4be-29b0-49d8-a79d-f8525a3bf398',
        ],
        id: '98f33268-4d7e-47b8-802d-c74508d06415',
      },
      'd36ef233-a1dd-4d46-9ca2-205465a86aed': {
        name: '½ CRISPY (MEDIANA)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'f5e581fd-17b2-43ba-b742-e59f32d7dca1',
          '9a66c4be-29b0-49d8-a79d-f8525a3bf398',
        ],
        id: 'd36ef233-a1dd-4d46-9ca2-205465a86aed',
      },
      '4dd3ce10-4fd8-4fff-b673-0a2a19a42bfc': {
        name: '½ TRADICIONAL (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3307bbc4-ad8d-4d6f-b67d-e983a237566d',
          '0e7ad22d-e1a4-4dee-9571-9b570264a68b',
        ],
        id: '4dd3ce10-4fd8-4fff-b673-0a2a19a42bfc',
      },
      '91a71a37-0868-4b89-a8dc-7afabad5bb4b': {
        name: '½ TRADICIONAL (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '3307bbc4-ad8d-4d6f-b67d-e983a237566d',
          '0e7ad22d-e1a4-4dee-9571-9b570264a68b',
        ],
        id: '91a71a37-0868-4b89-a8dc-7afabad5bb4b',
      },
      '3a693e67-beb2-4f10-9541-d50294d193c8': {
        name: '½ PAN PIZZA (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '28ef8657-2aec-4004-b6e1-4ddc255ef448',
          'e6d8901e-cbeb-48ed-8014-46e33631aee6',
        ],
        id: '3a693e67-beb2-4f10-9541-d50294d193c8',
      },
      '5a58993e-b58f-4b7b-91d4-2effaf3860d2': {
        name: '½ PAN PIZZA (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '28ef8657-2aec-4004-b6e1-4ddc255ef448',
          'e6d8901e-cbeb-48ed-8014-46e33631aee6',
        ],
        id: '5a58993e-b58f-4b7b-91d4-2effaf3860d2',
      },
      'a179346a-edeb-44b5-9396-783e4252b234': {
        name: '½ CRISPY (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'f95ec718-a763-43dc-8391-cb1d3693ed0e',
        ],
        id: 'a179346a-edeb-44b5-9396-783e4252b234',
      },
      'e439347d-8ace-4823-b7a7-7a81afadfb74': {
        name: '½ CRISPY (GRANDE)',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'f95ec718-a763-43dc-8391-cb1d3693ed0e',
        ],
        id: 'e439347d-8ace-4823-b7a7-7a81afadfb74',
      },
      '08acdb19-de79-4c52-8b33-c3fa1bc48286': {
        name: '½ 1 INGREDIENTE',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'cf974dc0-92a2-4450-8a7c-58eea67ce2db',
          '82bfe8b2-c965-4078-8700-2c346faa6525',
          'ae0a16a1-697f-40d5-b582-c6ea79d5c135',
        ],
        id: '08acdb19-de79-4c52-8b33-c3fa1bc48286',
      },
      'a00dae9e-3489-48fa-855d-dee8f7195c80': {
        name: '½ 1 INGREDIENTE MEDIANA',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          '4d31ba93-261d-4efd-a180-a171824d0364',
          '7f1480da-2363-4b7a-87a6-f3d7fd842253',
          'b27fd92b-b184-41cc-bef7-7581b7426216',
        ],
        id: 'a00dae9e-3489-48fa-855d-dee8f7195c80',
      },
      '484e119f-3ed1-4f8b-bead-9c434ec8ae6b': {
        name: '½ 1 INGREDIENTE',
        minimumSelections: 1,
        maximumSelections: 1,
        maxPerModifierSelectionQuantity: 1,
        itemIds: [
          'e6fa999a-444b-4737-b153-4e6434f16310',
          '814b3bc7-023a-4879-92b6-61b3f36e1058',
          'ecccb8cb-8ba2-4006-aaa8-020d7d74da28',
        ],
        id: '484e119f-3ed1-4f8b-bead-9c434ec8ae6b',
      },
    },
  },
  metadata: {
    categories: {
      fulfillmentMode: {
        DINE_IN: [
          'a7742de6-965d-4c44-aaee-37569748caac',
          '7452a843-aae7-46ce-91b1-9459d64e3136',
        ],
        PICKUP: [
          'ab67eaf6-0070-434f-ad51-2ded0c7b54c4',
          'a7742de6-965d-4c44-aaee-37569748caac',
          '7452a843-aae7-46ce-91b1-9459d64e3136',
          'ebff60da-09cf-466d-9798-0c6580bbc441',
        ],
      },
    },
    items: {
      '5539cfe2-f093-4869-bd7b-409f339989ee': {
        overwritePrice: 95,
      },
    },
  },
};

export const VOUCHER_ITEM: HubsterMenuPublishItem = {
  id: '0bd3d4b4-225a-4e85-b91a-5e098894eae5',
  name: '35 Nuggets',
  photoIds: ['https://photos.tryotter.com/menu-photos/6db1e285-b09d-4705-a90b-84132b6c958a.png'],
  price: {
    currencyCode: 'MXN',
    amount: 315,
  },
  priceOverrides: [],
  status: {
    saleStatus: SalesStatus.FOR_SALE,
  },
  description: '',
  modifierGroupIds: [
    '610d8796-3a79-4302-9402-6ca23e9f52dd',
    '7d86eda7-e971-44e2-afd8-de0a603eefcc',
  ],
  skuDetails: {
    skuSlug: '63712d41-66ad-4ecf-bce4-53e1a2b19982:ee01b915-cdae-477e-abd3-be53d8401018',
    barcodes: [],
    dietaryClassifications: [],
    allergenClassifications: [],
    containsAlcohol: false,
    servings: { min: 0, max: 10 },
    nutritionalInfo: {
      energyKcal: { low: 0, high: 10 },
    },
  },
  additionalCharges: [],
  tax: {
    percentageValue: {
      decimalValue: 0,
    },
    isValueAddedTax: true,
  },
};
