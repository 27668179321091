import { watchPausable } from '@vueuse/core';
import { useTimeStore } from './time';

export const useInactiveStoreV2 = defineStore('inactiveV2', () => {
  const timeStore = useTimeStore();
  const cartUtils = useCartUtils();

  const { now } = storeToRefs(timeStore);

  const inactivityInfo = reactive({
    inactivitySeconds: 30,
    displayModalSeconds: 10,
  });

  const displayModal = ref(false);

  const modalCounter = ref(0);
  const inactiveCounter = ref(0);

  const checkTimer = (timer: number, screen: number) => {
    inactiveCounter.value += 1;

    if (inactiveCounter.value === timer) {
      displayModal.value = true;
      inactiveCounter.value = 0;
      modalCounter.value = screen;
    }
  };

  const checkScreen = () => {
    modalCounter.value -= 1;

    if (modalCounter.value === 0) {
      displayModal.value = false;
      cartUtils.restartCart(true);
    }
  };

  const { pause: pauseChecking, resume: resumeChecking } = watchPausable(
    now,
    () => {
      if (displayModal.value) {
        checkScreen();
        return;
      }

      checkTimer(inactivityInfo.inactivitySeconds, inactivityInfo.displayModalSeconds);
    },
    { immediate: true },
  );

  const resetInactivity = () => {
    modalCounter.value = 0;
    inactiveCounter.value = 0;
    displayModal.value = false;
  };

  const setup = (data: {
    inactivitySeconds: number,
    displayModalSeconds: number,
  }) => {
    resetInactivity();
    inactivityInfo.inactivitySeconds = data.inactivitySeconds;
    inactivityInfo.displayModalSeconds = data.displayModalSeconds;
    resumeChecking();
  };

  return {
    setup,
    resetInactivity,
    inactivityInfo,
    pauseChecking,
    resumeChecking,
    modalCounter,
    inactiveCounter,
    displayModal,
  };
});
