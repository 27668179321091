<script lang="ts" setup>
import { PropType } from 'vue';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { SalesStatus } from '@slabcode/hubster-models/enums/hubster';
import { getProductImage } from '@common/utils';

const cartStore = useCartStoreV2();

const props = defineProps({
  product: {
    type: Object as PropType<HubsterMenuPublishItem>,
    required: true,
  },
  allowAlcohol: {
    type: Boolean,
    required: true,
  },
  simple: {
    type: Boolean,
    required: false,
    default: false,
  },
  priceDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
});

const emit = defineEmits(['addItem', 'showAlcoholConfirm']);

const { t } = useI18n();
const { allowAlcohol, product } = toRefs(props);

const containsAlcohol = computed(() => props.product.skuDetails.containsAlcohol);

const productImage = computed(() => getProductImage(props.product.photoIds));

const itemsInCart = computed(() => {
  const quantity = cartStore.itemCartCount(props.product.id);

  return quantity;
});

const alreadyAddedItem = computed(() => Number(itemsInCart.value) > 0);

const canBeAdded = computed(() => {
  const { status, skuDetails } = product.value;

  if (!allowAlcohol.value) {
    return !skuDetails.containsAlcohol;
  }

  return status?.saleStatus === SalesStatus.FOR_SALE;
});

const buttonLabel = computed(() => {
  if (canBeAdded.value) return t('ADD');

  return !allowAlcohol.value ? t('CONTAINS_ALCOHOL') : t('NOT_AVAILABLE');
});

function addItem() {
  if (containsAlcohol.value) {
    emit('showAlcoholConfirm');
    return;
  }

  if (canBeAdded.value) emit('addItem');
}
</script>

<template>
  <button
    type="button"
    class="card-container"
    :disabled="!canBeAdded"
    :class="[
      simple ? 'shadow-none p-0' : 'shadow-lg px-5 pb-5 pt-2.5',
      { 'opacity-50': !canBeAdded },
    ]"
    @click="addItem()"
  >
    <div
      v-if="alreadyAddedItem"
      class="item-quantity bg-kiosk-primary"
    >
      {{ itemsInCart }}
    </div>

    <div>
      <span v-if="!canBeAdded && !containsAlcohol" class="out-of-stock">{{ $t('PRODUCTS.OUT_OF_STOCK') }}</span>
      <span v-if="!canBeAdded && containsAlcohol" class="flex justify-center out-of-stock">
        <img class="w-28" src="/age-restriction.svg" alt="age-restriction" />
      </span>
      <KioskImage
        hide-border
        :image-class="`h-40 w-40 object-cover object-center rounded-md ${canBeAdded ? '' : 'opacity-50'}`"
        :src="productImage"
        :alt="product.name"
      />
    </div>

    <p class="product-name">
      {{ product.name }}
    </p>

    <CatalogItemPrice :product="product" />

    <KioskButton
      v-if="!simple"
      color="gradient"
      text-size="small"
      class="add-action"
      :class="canBeAdded ? 'px-4' : '!px-3'"
      :disabled="!canBeAdded"
    >
      {{ buttonLabel }}
    </KioskButton>
  </button>
</template>

<style scoped>
.item-quantity {
  @apply absolute flex items-center justify-center w-10 h-10 text-2xl text-white rounded-full right-2 top-2;
}

.add-action {
  @apply w-full py-2.5;
}

.card-container {
  @apply bg-white flex flex-col items-center justify-around w-full relative gap-4;
}

.product-name {
  @apply font-bold text-3xl tracking-tighter leading-8 text-center min-h-[64px];
}

.out-of-stock {
  @apply text-5xl mx-auto w-full font-semibold left-0 right-0 text-center absolute mt-[50%] -translate-y-1/2 z-10 uppercase;
}
</style>
