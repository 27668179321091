import { RouteName } from '@common/routes/routeName';
import { MultibrandData } from '../../welcome/interfaces';

export const useMultibrandStore = defineStore('multibrand', () => {
  const route = useRoute();
  const router = useRouter();
  const languageStore = useLanguageStore();
  const orderTypeStore = useOrderTypeStore();
  const metadataStoreV2 = useMetadataStoreV2();

  const storeId = ref<string>('');
  const multibrand = ref<boolean>(false);
  const backUrl = ref<string>('');
  const cancelUrl = ref<string>('');
  const successUrl = ref<string>('');

  const multibrandRedirect = () => {
    if (!metadataStoreV2.kioskSettings?.fulfillment.dineIn.enabled || !metadataStoreV2.kioskSettings?.fulfillment.pickUp.enabled) {
      const redirectTo = metadataStoreV2.brandSettings?.layout.category === CategoryLayouts.Disabled
        ? RouteName.PRODUCTS
        : RouteName.ORDER_CATEGORIES;
      router.push({ name: redirectTo });

      if (metadataStoreV2.kioskSettings?.fulfillment.dineIn.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.DINE_IN;
      }

      if (metadataStoreV2.kioskSettings?.fulfillment.pickUp.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.PICKUP;
      }

      return;
    }
    router.push({ name: RouteName.ORDER_TYPE });
  };

  const setup = () => {
    if (!('multibrand' in route.query)) return false;

    const data = route.query as unknown as MultibrandData;
    // Set values to store
    multibrand.value = true;
    storeId.value = data.storeId;
    backUrl.value = data.backUrl;
    cancelUrl.value = data.cancelUrl;
    successUrl.value = data.successUrl;

    languageStore.setLanguage(data.language);

    // After init, redirects to order type
    multibrandRedirect();
    return true;
  };

  return {
    setup,
    multibrandRedirect,
    storeId,
    multibrand,
    backUrl,
    cancelUrl,
    successUrl,
  };
});
