import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { selectedItems } from '@common/utils/modifierSelectedItems';

function getModifierDescription(item: ItemDescriptor) {
  let result = item.itemMeta.name;
  const items = selectedItems(item.modifiers);

  if (!items.length) return result;

  items.forEach((_item) => {
    result += ` (${_item.quantity} ${getModifierDescription(_item)})`;
  });

  return result;
}

export function itemDescription(item: ItemDescriptor): string {
  let description = '';
  const items = selectedItems(item.modifiers);
  if (selectedItems.length === 0) return description;

  const { t } = i18n.global;
  items.forEach((_item) => {
    if (_item.parent?.modifierMeta.name.includes('Upgrade')) {
      description += `${t('ORDER.UPGRADE_LABEL')} + `;
      return;
    }

    description += `${_item.quantity}x ${
      selectedItems(_item.modifiers).length
        ? getModifierDescription(_item)
        : _item.itemMeta.name
    } + `;
  });

  // Remove last '+ '
  return description.slice(0, -2);
}
