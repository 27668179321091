import { NavigationGuardNext, RouteLocationNormalizedGeneric } from 'vue-router';

// Protect the table route if fulfillment table option is disabled
export function tablePageGuard(to: RouteLocationNormalizedGeneric, from: RouteLocationNormalizedGeneric, next: NavigationGuardNext) {
  const tableController = useTableController();
  const availableStepsController = useAvailableStepsController();
  if (!tableController.fulfillment.displayTable) {
    const name = availableStepsController.getRouteNameExcludingTo(to.name as string, from.name as string);
    next({
      name,
    });
  } else {
    next();
  }
}
