<script setup lang="ts">
import { RouteName } from '@common/routes/routeName';

const router = useRouter();

function goMenu() {
  router.push({ name: RouteName.PRODUCTS });
}
</script>
<template>
  <div
    class="flex flex-col items-center justify-center gap-[52px] w-full h-full max-w-[384px] mx-auto"
  >
    <p class="text-[40px] text-center font-medium uppercase">
      {{ $t('ORDER.EMPTY_CART') }}
    </p>
    <div class="flex flex-col items-center">
      <KioskButton text-size="medium" color="primary" class="mb-7" @on-click="goMenu()">
        {{ $t('ORDER.BACK_MENU') }}
      </KioskButton>
      <div class="w-[119px] h-[1px] bg-[#CDBBBB]" />
      <p class="max-w-lg text-2xl text-center mt-7">
        {{ $t('ORDER.EMPTY_CART_DESCRIPTION') }}
      </p>
    </div>
  </div>
</template>
