import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import { GTMHistoryItem } from '@common/interfaces/gtmHistoryItem';

export function itemToHistoryItem(item: ItemDescriptor): GTMHistoryItem {
  const level = item.level - 1;
  return {
    name: item.itemMeta.name,
    id: item.itemMeta.id,
    quantity: item.quantity,
    price: item.itemMeta.price.amount,
    level,
    section: item.parent!.modifierMeta.name,
    parentId: level > 0 ? item.parent?.parent.itemMeta.id : undefined,
    parentName: level > 0 ? item.parent?.parent.itemMeta.name : undefined,
  };
}
