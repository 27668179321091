export const pt = {
  FROM: 'A partir de',
  SCAN_QR: 'Digitalizar o QR',
  COUPON_SUCCESS: 'Cupão aplicado com sucesso!',
  CLAIM_COUPON: 'Resgatar cupão',
  COUPON_QR_DESCRIPTION: 'Aproxime o código QR para a luz verde do nosso Quiosque.',
  VALIDATE_QR: 'Validar',
  WRITE_COUPON_CODE: 'Introduza o seu código de cupão aqui',
  INSERT_TABLE_NUMBER: 'Insira o número da mesa',
  SCAN_QR_OR_TABLE_NUMBER: 'Digitalize o QR ou digite o número da localizador',
  TABLE: 'Mesa',
  NEXT: 'Próximo',
  BACK: 'Voltar',
  OPTIONAL: 'Opcional',
  DONE: 'Pronto',
  SELECT_LANGUAGE: 'Selecione o idioma',
  SELECT_ORDER_TYPE: 'Onde você quer comer?',
  SOMETHING_WENT_WRONG: 'Algo deu errado, tente novamente ou mais tarde',
  INTERNAL_ERROR: 'Erro interno',
  EAT_HERE: 'Coma aqui',
  RESTART: 'Reiniciar',
  RETRY: 'Tente novamente',
  CONTINUE: 'Continuar',
  ACCEPT: 'Aceitar',
  EDIT: 'Editar',
  DELETE: 'Excluir',
  TAKE_AWAY: 'Para levar',
  PICK_UP_COUNTER: 'Retire no balcão',
  CRAVING_SELECTION: 'O que você deseja?',
  TOTAL: 'Total',
  TAXES: 'Impostos',
  DISCOUNTS: 'Descontos',
  SUBTOTAL: 'Subtotal',
  ADD: 'Adicionar',
  NOT_AVAILABLE: 'Não disponível',
  ADD_CART: 'Adicionar ao carrinho',
  SAVE: 'Manter',
  CUSTOMIZE: 'Customizar',
  QUANTITY: 'Quantia',
  PRICE: 'Preço',
  ITEMS: 'Itens',
  SKIP: 'Pular',
  TYPE_HERE: 'Escreva aqui',
  ADD_VOUCHER: 'Adicionar cupom',
  SCAN_VOUCHER: 'Digitalize o código QR',
  ADD_ANOTHER_VOUCHER: 'Adicione outro cupom',
  GO_BACK: 'Retornar',
  VOUCHER_REJECTED: 'Cupom rejeitado',
  VOUCHER_REJECTED_DETAIL: 'Desculpe, ocorreu um erro ao ler o código QR. Por favor, tente novamente',
  CASH: 'Dinheiro',
  CARD: 'Cartão',
  PAY_ON_SITE: 'Pague no caixa',
  MAIN_MENU: 'Menu principal',
  MODIFIERS_BACK_TITLE: 'Quer voltar ao Catálogo?',
  MODIFIERS_BACK_DESCRIPTION: 'Você perderá o progresso na personalização deste produto',
  CANCEL: 'Cancelar',
  CONTAINS_ALCOHOL: 'Contém álcool',
  TIMELINE: {
    CHOOSE_PRODUCT: 'Selecione o produto',
    CUSTOMIZATION: 'Customizar',
    CONFIRM: 'Confirme',
    PAY: 'Pagar',
  },
  ORDER: {
    BACK_MENU: 'Voltar ao menu',
    BACK_CART: 'Voltar ao carrinho',
    UPGRADE_MODIFIER_QUESTION: 'Quer aumentar seu combo?',
    ACCEPT_UPGRADE: 'Sim',
    DENY_UPGRADE: 'Não',
    UPGRADE_MODIFIER_DESC: 'O combo vai ficar maior',
    UPGRADE_LABEL: 'Combo ampliado',
    EMPTY_CART: 'Ops! Seu carrinho está vazio',
    EMPTY_CART_DESCRIPTION: 'Ou escolha algo delicioso no carrossel para adicionar ao carrinho.',
  },
  CANCEL_ORDER: {
    QUESTION: 'Deseja cancelar este pedido?',
    ADVICE: 'O progresso que você obteve até este ponto será perdido e você retornará ao início',
    CONFIRMATION: 'Sim, cancelar',
    CANCEL: 'Voltar',
  },
  DELETE_ITEM: {
    QUESTION: 'Deseja excluir este produto?',
    ADVICE: 'Você vai eliminar o produto',
    CONFIRMATION: 'Sim, exclua',
  },
  INACTIVITY: {
    TITLE: 'Você ainda está aí?',
    ADVICE: 'Em alguns segundos a ordem será restaurada e você retornará ao início',
    QUESTION: 'Quer continuar com seu pedido?',
  },
  MODIFIERS_MODAL: {
    TITLE: 'Personalize os ingredientes',
  },
  CHECKOUT: {
    CLIENT_INFO_TITLE: 'Digite seu nome e ligaremos para você quando seu pedido estiver pronto',
    SUMMARY_TITLE: 'Revise seu resumo de compra e efetue o pagamento',
    SUMMARY_PAYMENT_MESSAGE: 'Mova ou passe o cartão pelo terminal para concluir sua compra',
    GET_CLOSE_CARD: 'Insira o cartão no terminal',
    REJECTED_PAY: 'Pagamento Recusado',
    REJECTED_PAY_AGAIN: 'Pagamento recusado novamente',
    REJECTED_DESCRIPTION: 'Lamentamos o ocorrido, por favor <b>vá até a finalização da compra</b> para fazer seu pedido',
    CASH_PAY: 'Pagar em dinheiro',
    PROCESSING: 'Processando seu pedido',
    CANCEL: 'Para cancelar um pedido',
    TABLE: 'Escolha como deseja receber seu pedido',
    YOU_CAN_ALSO: 'você também pode',
  },
  SUCCESS_ORDER: {
    TITLE: 'Pedido criado com sucesso',
    DESCRIPTION: 'Por favor, <b>vá até a caixa registradora especial do quiosque</b> e pague seu pedido lá',
    ORDER: 'Número do pedido',
    CARD_DESCRIPTION: 'seu pagamento foi realizado com sucesso, <b>aproveite seu pedido</b>',
    POS_FAIL: 'Por favor <b>vá até a finalização da compra</b> com a fatura para verificar seu pedido',
  },
  SUCCESS_ORDER_CARD: {
    TITLE: 'Pagamento realizado com sucesso',
    DESCRIPTION: 'Obrigado pela sua compra, seu pedido estará pronto em alguns minutos',
    VOUCHER: 'Cupom resgatado com sucesso',
  },
  USER_INFO: {
    NAME: 'Nome',
    NAME_NIT: 'Nome ou razão social',
    COMPANY_NAME: 'Razão social',
    EMAIL: 'E-mail',
    DOCUMENT_ID: 'Número de identificação / CNPJ',
    PHONE: 'Número de telefone',
    PHONE_PLACEHOLDER: '3101234567',
    EMAIL_PLACEHOLDER: "email{'@'}email.com",
    NAME_PLACEHOLDER: 'Nome último nome',
    DOCUMENT_PLACEHOLDER: 'Sem dígito de verificação',
    VALIDATIONS: {
      REQUIRED: 'O campo "{field}" é obrigatório',
      EMAIL: 'O formato do e-mail é inválido',
      ALPHA: 'O campo deve conter apenas letras',
      BILLING_NEEDED: 'Dados necessários para emissão de fatura eletrónica',
      BILLING_ID_NEEDED: 'Dados necessários para emissão de fatura eletrónica e acumulação de pontos',
      BILLING_NAMES_NEEDED: 'Nomes e sobrenomes ou razão social são necessários para emissão de nota fiscal eletrônica',
      MIN_LENGTH: 'O campo "{field}" deve ter pelo menos {length} caracteres',
      MAX_LENGTH: 'O campo "{field}" deve ter no máximo {length} caracteres',
    },
    CLIENT_TYPE: {
      REGULAR: 'Pessoa natural',
      COMPANY: 'Pessoa jurídica',
    },
  },
  ALCOHOL: {
    TITLE: 'Atenção',
    GALLERY_MESSAGE: 'Este produto contém álcool.\nConfirme que você é maior de idade antes de prosseguir com a compra',
    MODIFIERS_MESSAGE: 'Algumas opções deste produto contêm álcool.\nConfirme que você é maior de idade antes de escolher suas opções',
    CONFIRM: 'Tenho mais de 18 anos',
    CANCEL: 'Eu sou menor de idade',
  },
  SPACE: 'espaço',
  ORDER_HERE: 'Toque para fazer o pedido!',
  PRODUCTS: {
    OUT_OF_STOCK: 'Esgotado',
  },
  LEGAL: {
    TERMS_AND_CONDITIONS: 'Eu aceito os Termos e Condições',
    PRIVACY_POLICY: 'Eu aceito as Políticas de Privacidade',
  },
  CURRENCY: {
    MODAL_TITLE: 'Com que moeda deseja pagar?',
  },
  SOMETHING_WENT_WRONG_PAGE: {
    TITLE: 'Algo deu errado',
    DESCRIPTION: 'Não se preocupe, isso pode acontecer de vez em quando',
    BACK_HOME: 'Voltar ao início',
  },
};

export default pt;
