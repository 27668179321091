<script setup lang="ts">
import { useMousePressed } from '@vueuse/core';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { RouteName } from '@common/routes/routeName';
import { useMenuStore } from '@/modules/common/stores/menu';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const couponCode = ref('');
const voucherAction = ref();
const categoriesEl = ref<HTMLDivElement>();
const { pressed } = useMousePressed();
const couponInput = ref<HTMLInputElement>();
const menuStore = useMenuStore();
const cartStore = useCartStoreV2();
const showVoucherDialog = ref(false);
const showAlcoholConfirm = ref(false);
const metadataStoreV2 = useMetadataStoreV2();

const { brandSettings } = storeToRefs(metadataStoreV2);
const { alcohol, categories, voucherProduct } = storeToRefs(menuStore);
const { triggerAddToCart, triggerSelectCategory } = useGTMEventsComposableV2();

const categoryColumns = computed(() => {
  if (!brandSettings.value) return undefined;
  return brandSettings.value.layout.category;
});

const containsAlcohol = computed(() => {
  if (!voucherProduct.value) return false;

  return voucherProduct.value.skuDetails.containsAlcohol;
});

const voucherTitle = computed(() => {
  if (couponCode.value.length > 0 && !voucherProduct.value) {
    // Handle errors
    return t('VOUCHER_REJECTED');
  }

  return (!voucherProduct.value ? t('SCAN_VOUCHER') : t('SUCCESS_ORDER_CARD.VOUCHER'));
});

const gridColumns = computed(() => {
  if (!categoryColumns.value) return 'w-1/3 grid-cols-3';

  return `grid-cols-${categoryColumns.value}`;
});

const categoryImageClass = computed(() => {
  let result = 'object-cover object-center rounded-xs ';

  if (!categoryColumns.value) {
    result += 'w-44 h-44';
  }

  if (categoryColumns.value === '2') {
    result += 'w-56 h-56';
  }

  return result;
});

function focusCouponInput() {
  if (couponInput.value) couponInput.value.focus();
}

// Focus input after be rendered
watch(couponInput, (newValue) => {
  if (newValue) focusCouponInput();
});

// Focus input after any click with showVoucherDialog active
watch(pressed, (newValue) => {
  if (newValue && showVoucherDialog.value) focusCouponInput();
});

function closeVoucherDialog() {
  couponCode.value = '';
  showVoucherDialog.value = false;
  // Reset voucher data
  menuStore.resetVoucherProduct();
}

/**
 * Select some category to display the corresponding items and redirect to products view
 * @param {string} categoryId Represents the choosen category
 */
function goToProducts(category: HubsterCategory) {
  menuStore.selectCategory(category.id);
  // Redirect to products list view
  router.push({ name: RouteName.PRODUCTS });
  // Trigger GTM events
  triggerSelectCategory(category);
}

// TODO: CHeck missing logic
function addVoucherProduct(product: HubsterMenuPublishItem) {
  // Add to cart
  const addedItem = cartStore.addItemWithoutCustomization(product.id);
  // Trigger GTM event
  triggerAddToCart({
    item: toGTMCartItem(addedItem),
    category: { name: 'COUPONS', id: 'COUPONS' },
    section: route.name,
    operation: CartEventType.ADD,
  });
}

function denyAlcoholProducts() {
  couponCode.value = '';
  closeVoucherDialog();
  voucherAction.value = undefined;
  menuStore.setAlcoholValue({ allow: false });
}

function checkVoucherProduct(action: Function) {
  if (alcohol.value.checked) {
    action();
    return;
  }

  if (!showAlcoholConfirm.value && containsAlcohol.value) {
    showAlcoholConfirm.value = true;
    voucherAction.value = action;
    return;
  }

  action();
}

function addProductAndStay() {
  if (voucherProduct.value) {
    addVoucherProduct(voucherProduct.value);
    couponCode.value = '';
    menuStore.resetVoucherProduct();
  }
}

function addProductAndGoToCategory() {
  const [hotCategory] = categories.value;

  if (voucherProduct.value) {
    addVoucherProduct(voucherProduct.value);
    closeVoucherDialog();
    goToProducts(hotCategory);
  }
}

watch(couponCode, (newValue) => {
  // Find product in menu
  if (newValue.length > 0) {
    menuStore.findVoucherProductById(newValue);
  }
});
</script>

<template>
  <section class="order-categories">
    <h1 class="kfc-text text-[52px]">
      {{ t("CRAVING_SELECTION") }}
    </h1>

    <ScrollContainer
      v-if="categories.length > 0"
      ref="categoriesEl"
      width="720px"
      height="1140px"
    >
      <div
        :class="gridColumns"
        class="my-6 categories-container animate__animated animate__fadeIn"
      >
        <button
          type="button"
          class="categories-image-button"
          v-for="category in categories"
          :key="category.id"
          @click="goToProducts(category)"
        >
          <OrderMenuItem :product="category" :class="categoryImageClass">
            <p class="text-4xl break-words w-full min-h-[80px]">
              {{ category.name }}
            </p>
          </OrderMenuItem>
        </button>
      </div>
    </ScrollContainer>
  </section>

  <!-- TODO: Create CouponDialog component -->
  <KioskModal
    v-if="showVoucherDialog"
    modal-class="!w-[588px] relative"
  >
    <h3 class="dialog-title">
      {{ voucherTitle }}
    </h3>

    <template v-if="voucherProduct">
      <!-- TODO: Add alcochol logic to voucher -->
      <CatalogItem
        simple
        :allow-alcohol="alcohol.allow"
        :product="voucherProduct"
        class="my-8"
      />
      <!-- Product actions -->
      <KioskButton
        icon="qr"
        color="outline"
        class="w-full my-4"
        @on-click="checkVoucherProduct(addProductAndStay)"
      >
        {{ t('ADD_ANOTHER_VOUCHER') }}
      </KioskButton>

      <KioskButton
        color="primary"
        class="w-full !py-5 my-4"
        @on-click="checkVoucherProduct(addProductAndGoToCategory)"
      >
        {{ t('NEXT') }}
      </KioskButton>
    </template>
  </KioskModal>

  <AlcoholConfirm
    v-if="showAlcoholConfirm"
    @cancel="denyAlcoholProducts()"
    @confirm="voucherAction()"
  />
</template>

<style scoped>
.order-categories {
  @apply flex flex-col items-center gap-5;
}

.categories-container {
  @apply grid gap-8;
}

.categories-image-button {
  @apply flex flex-col gap-2 items-center justify-start px-2;
}

.invalid-code {
  @apply font-light text-2xl leading-8 text-center my-4;
}
</style>
