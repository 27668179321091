<script setup lang="ts">
import { PropType } from 'vue';
import { useImage } from '@vueuse/core';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  imageClass: {
    type: String,
    required: false,
    default: '',
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  hideBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  loaderSize: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
});

const { loaderSize, src } = toRefs(props);
const loaderOptions = {
  small: {
    size: 'w-[38px] h-[38px]',
    transform: 'translate(1 1)',
    strokeWidth: 'stroke-[2px]',
    circlePos: 18,
    path: 'M36 18c0-9.94-8.06-18-18-18',
  },
  medium: {
    size: 'w-[76px] h-[76px]',
    transform: 'translate(2 2)',
    strokeWidth: 'stroke-[4px]',
    circlePos: 36,
    path: 'M72 36c0-18.188-16.12-36-36-36',
  },
  large: {
    size: 'w-[114px] h-[114px]',
    transform: 'translate(3 3)',
    strokeWidth: 'stroke-[6px]',
    circlePos: 36,
    path: 'M108 54c0-27.282-24.18-54-54-54',
  },
};

const loaderStyles = computed(() => loaderOptions[loaderSize.value]);

const { isLoading } = useImage({ src: src.value });
</script>

<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="flex items-center justify-center kfc-image"
    :class="imageClass"
  >
    <svg
      v-if="isLoading"
      class="animate-spin stroke-kiosk-primary"
      :class="loaderStyles.size"
    >
      <g fill="none" fill-rule="evenodd">
        <g
          :class="loaderStyles.strokeWidth"
          :transform="loaderStyles.transform"
        >
          <circle
            stroke-opacity="0.5"
            :cx="loaderStyles.circlePos"
            :cy="loaderStyles.circlePos"
            :r="loaderStyles.circlePos"
          />
          <path :d="loaderStyles.path"></path>
        </g>
      </g>
    </svg>

    <img
      v-else
      :src="src"
      rel="preload"
      v-bind="$attrs"
      :alt="alt"
      :class="[
        imageClass,
        hideBorder ? '' : 'border border-neutral-500/50',
      ]"
    >
  </div>
</template>
