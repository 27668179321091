import { StepConfig } from '../interfaces/setupStep';

export const fulfillmentConfig: StepConfig = {
  setupShowConfig: (metadata) => {
    if (!metadata.kioskSettings) {
      return null;
    }
    const { dineIn, pickUp } = metadata.kioskSettings.fulfillment;
    const carouselImage = metadata.customImages?.carousel;

    if (dineIn.enabled && pickUp.enabled) {
      return carouselImage ? RouteName.ORDER_TYPE : RouteName.FULFILLMENT;
    }

    return null;
  },

};
