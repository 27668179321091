<script lang="ts" setup>
const errorStore = useErrorStore();
const route = useRoute();
const showErrors = ref(false);
</script>

<template>
  <section class="full-hd content">
    <span class="text-8xl icon icon-warning" />
    <div class="flex flex-col items-center gap-4">
      <h1 class="text-5xl">
        {{ $t("SOMETHING_WENT_WRONG_PAGE.TITLE") }}.
      </h1>
      <p class="text-3xl text-balance">
        {{ $t("SOMETHING_WENT_WRONG_PAGE.DESCRIPTION") }}.
      </p>
    </div>
    <a
      :href="`/${route.params.integration}/${route.params.storeId}`"
      class="back-btn"
    >
      {{ $t("SOMETHING_WENT_WRONG_PAGE.BACK_HOME") }}
    </a>
    <button type="button" @click="showErrors = !showErrors" class="check-errors-btn">
      <div class="flex flex-col items-end">
        <div v-if="showErrors" class="error-popup">
          <h4 class="text-4xl font-bold">
            Error
          </h4>
          <p>
            {{ errorStore.error }}
          </p>
        </div>
        <span class="text-5xl text-gray-200 icon icon-eye" />
      </div>
    </button>
  </section>
</template>

<style scoped>
.content {
  @apply flex flex-col items-center justify-center p-32 gap-12 relative;
}

h1 {
  @apply text-5xl font-bold;
}

p {
  @apply text-3xl;
}

.back-btn {
  @apply px-6 py-4 text-2xl text-white uppercase rounded-full bg-gradient-to-r from-red-600 to-red-800;
}

.check-errors-btn {
  @apply absolute bottom-10 right-10;
}

.error-popup {
  @apply flex flex-col items-start p-10 mb-5 border rounded-md;
}
</style>
