import { StepConfig } from '../interfaces/setupStep';

// Remove the table page if the setting is to different to fulfillment view.
export const tableConfig: StepConfig = {
  setupShowConfig(metadata) {
    if (metadata.brandSettings?.layout.tableNumber === 'fulfillmentView') {
      return RouteName.TABLE;
    }

    return null;
  },
};
