import { RouteName } from '@common/routes/routeName';
import { FulfillmentMode } from '@common/enums/FulfillmentMode';

export function useFulfillmentController() {
  const router = useRouter();

  const metadataStoreV2 = useMetadataStoreV2();
  const multibrandStore = useMultibrandStore();
  const orderTypeStore = useOrderTypeStore();
  const kioskImagesStore = useKioskImagesStore();
  const availableStepsController = useAvailableStepsController();

  const { kioskSettings } = storeToRefs(metadataStoreV2);

  /*
   * Redirect when you are in order type view
   */
  const redirectFromFulfillment = (fulfillment: FulfillmentMode) => {
    orderTypeStore.fulfillmentMode = fulfillment;
    availableStepsController.nextStep();
  };

  function redirectFromMultibrand() {
    if (
      !metadataStoreV2.kioskSettings?.fulfillment.dineIn.enabled
      || !metadataStoreV2.kioskSettings?.fulfillment.pickUp.enabled
    ) {
      const redirectTo = metadataStoreV2.hideCategories
        ? RouteName.PRODUCTS
        : RouteName.ORDER_CATEGORIES;

      router.push({ name: redirectTo });

      if (metadataStoreV2.kioskSettings?.fulfillment.dineIn.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.DINE_IN;
      }

      if (metadataStoreV2.kioskSettings?.fulfillment.pickUp.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.PICKUP;
      }

      return;
    }
    router.push({ name: RouteName.ORDER_TYPE });
  }

  function redirectToMultibrand() {
    let redirectTo = RouteName.PRODUCTS;

    if (
      metadataStoreV2.kioskSettings?.fulfillment.dineIn.enabled
      && metadataStoreV2.kioskSettings?.fulfillment.pickUp.enabled
    ) {
      redirectTo = kioskImagesStore.customImages64?.carousel
        ? RouteName.ORDER_TYPE
        : RouteName.FULFILLMENT;
    } else if (!metadataStoreV2.hideCategories) {
      redirectTo = RouteName.ORDER_CATEGORIES;
    }

    router.push({ name: redirectTo });
  }

  /*
   * Redirect when you are in welcome view
   */
  const redirectFromWelcome = () => {
    const carouselImage = kioskImagesStore.customImages64?.carousel;

    if (!kioskSettings.value) {
      console.warn('there are not kiosk settings');
      return;
    }

    const { dineIn, pickUp } = kioskSettings.value.fulfillment;

    // Go to order type if both are enabled
    if (dineIn.enabled && pickUp.enabled) {
      router.push({
        name: carouselImage ? RouteName.ORDER_TYPE : RouteName.FULFILLMENT,
      });
      return;
    }

    // Assume one of them as unique answer and ignore order type screen
    if (dineIn.enabled) {
      redirectFromFulfillment(FulfillmentMode.DINE_IN);
    }

    if (pickUp.enabled) {
      redirectFromFulfillment(FulfillmentMode.PICKUP);
    }

    if (multibrandStore?.multibrand) {
      redirectFromMultibrand();
    }
  };

  return { redirectFromFulfillment, redirectFromWelcome, redirectToMultibrand };
}
