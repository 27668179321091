import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { RouteName } from '@common/routes/routeName';

export const useErrorStore = defineStore('error', () => {
  const error = ref<string>();

  const router = useRouter();

  /**
   * Handles errors by capturing them with Sentry.
   * If the error is an AxiosError, it captures the error message and request details.
   * Otherwise, it captures the entire error object.
   *
   * @param err - The error to handle.
   */
  const handleError = (err: unknown) => {
    if (err instanceof AxiosError) {
      console.error(`${err.message.toUpperCase()} calling '${err.config?.method?.toUpperCase()} ${err.config?.baseURL}/${err.config?.url}'`, err);
    } else {
      console.error('Unhandled error:', err);
    }

    Sentry.captureException(err);
  };

  /**
   * Handles an error and redirects to something went wrong page.
   *
   * @param err - The error object.
   * @param msg - The error message to display.
   */
  const handleAndRedirect = (err: unknown, msg: string) => {
    handleError(err);
    error.value = msg;
    router.push({ name: RouteName.SOMETHING_WENT_WRONG });
  };

  return { handleAndRedirect, handleError, error };
});
