<script lang="ts" setup>
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { getProductImage } from '@common/utils';
import { ProductsCategory } from '../interfaces/productsCategory';

const props = defineProps({
  product: {
    type: Object as PropType<ProductsCategory | HubsterCategory>,
    required: true,
  },
});

const productImage = computed(() => getProductImage(props.product.photoIds));
</script>
<template>
  <KioskImage
    image-class="object-cover object-center w-32 h-32 rounded-md"
    :src="productImage"
    :alt="product.name"
    v-bind="$attrs"
    hide-border
  />
  <slot />
</template>
