import { StepConfig } from '../interfaces/setupStep';

/**
 * Provides a controller for managing available steps in a multi-step process.
 *
 * @returns An object containing the available steps and related functions.
 */
export const useAvailableStepsController = defineStore('availableSteps', () => {
  // Dependencies
  const metadataStoreV2 = useMetadataStoreV2();
  const router = useRouter();
  const route = useRoute();

  // State
  const steps = ref<string[]>([]);

  function setup() {
    steps.value = [RouteName.WELCOME];

    const configViews: StepConfig[] = [
      fulfillmentConfig,
      tableConfig,
      categoriesConfig,
    ];

    configViews.forEach((configView) => {
      const enabledRoute = configView.setupShowConfig(metadataStoreV2);
      if (enabledRoute) {
        steps.value.push(enabledRoute);
      }
    });

    steps.value.push(RouteName.PRODUCTS);
  }

  function nextStep() {
    const index = steps.value.findIndex((step) => step === route.name);
    const nextRoute = steps.value[index + 1];
    router.push({ name: nextRoute });
  }

  /**
   * Returns the route name excluding the specified 'to' route, based on the 'from' route.
   * example:
   *  ⬇︎returns  ⬇︎︎ to        ⬇︎︎from
   * [Welcome, Fulfillment, Table, Categories, Products]
   *           ⬇︎︎ from       ⬇︎︎to    ⬇︎︎returns
   * [Welcome, Fulfillment, Table, Categories, Products]
   * @param {string} to - The route name to exclude.
   * @param {string} from - The current route name.
   * @returns {string} - The route name excluding the 'to' route.
   */
  function getRouteNameExcludingTo(to: string, from: string): string {
    const index = steps.value.findIndex((step) => step === to);
    const difference = index - steps.value.findIndex((step) => step === from);
    return steps.value[index + difference];
  }

  return { steps, setup, nextStep, getRouteNameExcludingTo };
});
