import { StepConfig } from '../interfaces/setupStep';

export const categoriesConfig: StepConfig = {
  setupShowConfig(metadata) {
    if (!metadata.hideCategories) {
      return RouteName.ORDER_CATEGORIES;
    }

    return null;
  },
};
