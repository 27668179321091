import { RouteName } from '@common/routes/routeName';
import { useTimeStore } from '@/modules/time/stores/time';

export const useStandbyBannerStore = defineStore('standbyBanner', () => {
  const openTodayTime = ref<Date>();
  const closingTodayTime = ref<Date>();
  const openTomorrowTime = ref<Date>();

  const route = useRoute();
  const router = useRouter();
  const timeStore = useTimeStore();
  const metadataStoreV2 = useMetadataStoreV2();

  const { now } = storeToRefs(timeStore);
  /**
   * Sets the schedule for the standby banner.
   * @param todayOpenAt - The opening date for today.
   * @param todayCloseAt - The closing date for today.
   * @param nextDayOpenAt - The opening date for the next day.
   */
  function setSchedule(todayOpenAt: string, todayCloseAt: string, nextDayOpenAt: string) {
    const openingToday = new Date(todayOpenAt);
    openTodayTime.value = openingToday;

    const closingToday = new Date(todayCloseAt);
    closingTodayTime.value = closingToday;

    const openTomorrow = new Date(nextDayOpenAt);
    openTomorrowTime.value = openTomorrow;
  }

  /**
   * Represents the watcher for the `isOpen` value to redirect the user to the correct page.
   */
  const redirectPage = (open: boolean) => {
    if (open && route.name !== RouteName.WELCOME) {
      router.push({ name: RouteName.WELCOME });
    }

    if (!open && route.name === RouteName.WELCOME) {
      router.push({ name: RouteName.STAND_BY_BANNER });
    }
  };

  /**
   * Checks the current time and updates the `isOpen` value based on the opening and closing times.
   */
  async function checkClose(nowTime: number) {
    if (!closingTodayTime.value || !openTomorrowTime.value || !openTodayTime.value) return;

    const close = closingTodayTime.value.getTime();
    const open = openTodayTime.value.getTime();
    const reopen = openTomorrowTime.value.getTime();

    if (nowTime < open) {
      redirectPage(false);
      return;
    }

    if (open < nowTime && nowTime < close) {
      redirectPage(true);
      return;
    }

    if (close < nowTime && nowTime < reopen) {
      redirectPage(false);
      return;
    }

    if (reopen < nowTime) {
      timeStore.pause();
      await metadataStoreV2.getMetadata();
      timeStore.resume();

      redirectPage(true);
    }
  }

  /**
   * The handle for the watcher that monitors changes in kiosk schedules.
   */
  const setup = () => {
    if (!metadataStoreV2.kioskSchedules) {
      console.warn('You need to fetch the metadata before setting up the root');
      return;
    }

    setSchedule(
      metadataStoreV2.kioskSchedules.openingTime,
      metadataStoreV2.kioskSchedules.closingTime,
      metadataStoreV2.kioskSchedules.reopeningTime,
    );
  };

  /**
   * Represents the watcher for the current time to check if the kiosk is open or closed.
   */
  const unwatchNow = watch(now, (currentDate) => {
    checkClose(currentDate.getTime());
  });

  /**
   * Stops checking for updates by unwatching the necessary properties.
   */
  const stopChecking = () => {
    unwatchNow();
  };

  return {
    setup,
    redirectPage,
    stopChecking,
    openTodayTime,
    closingTodayTime,
    openTomorrowTime,
  };
});
