<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { ValidationArgs } from '@vuelidate/core';
import { Customer } from '../interfaces/customer';
import { CustomerItem } from '../interfaces/customerItem';

const { t } = useI18n();
const router = useRouter();
const cartStoreV2 = useCartStoreV2();
const webhookStore = useWebhookStoreV2();
const metadataStoreV2 = useMetadataStoreV2();
const { customer, jobResponse, paymentInProgress } = storeToRefs(webhookStore);
const { clusterSettings } = storeToRefs(metadataStoreV2);

const { triggerGoBack } = useGTMEventsComposableV2();
const legalPolicies = useLegalPolicies();
const tableController = useTableController();
const route = useRoute();
const { closeCurrencyModal, isOpenCurrencyModal } = useCurrencyModal();
const { canContinue } = storeToRefs(legalPolicies);
const couponStore = useCouponStore();

const hideSummary = ref(false);

const collapseSteps = ref<{
  customerInfo?: { collapse: boolean; checked: boolean };
  payment?: { collapse: boolean; checked: boolean };
  tableNumber?: { collapse: boolean; checked: boolean };
}>({});

const showCustomerView = computed(() =>
  Object.values(clusterSettings.value?.basics.customerInfo ?? {}).some(
    (item) => item === true,
  ));

const currentStepIndex = computed(() =>
  Object.values(collapseSteps.value).findIndex((stp) => stp.collapse === false));

const showClientType = computed(
  () => clusterSettings.value?.basics.customerInfo.showClientType,
);

const orderTotal = computed(
  (): Partial<HubsterOrderTotal> => {
    const { subtotal, taxes } = cartStoreV2;

    const total = subtotal - couponStore.discount;
    const subtotalWithoutTaxes = subtotal - taxes;

    return {
      subtotal: taxes === 0 ? subtotal : subtotalWithoutTaxes,
      tax: taxes,
      discount: couponStore.discount,
      total,
    };
  },
);

const userFields = computed(
  (): Map<keyof Customer, CustomerItem> => {
    const values = new Map();
    const isNaturalPerson = customer.value.clientType === false;

    const fields: Partial<Record<keyof Customer, CustomerItem>> = {
      name: {
        label: t('USER_INFO.NAME'),
        type: 'text',
        icon: 'account',
        info: t('USER_INFO.VALIDATIONS.BILLING_NAMES_NEEDED'),
        placeholder: isNaturalPerson
          ? t('USER_INFO.NAME_PLACEHOLDER')
          : t('USER_INFO.COMPANY_NAME'),
      },
      taxIdentificationNumber: {
        label: t('USER_INFO.DOCUMENT_ID'),
        type: 'number',
        icon: 'citizen-id',
        info: t('USER_INFO.VALIDATIONS.BILLING_ID_NEEDED'),
        placeholder: t('USER_INFO.DOCUMENT_PLACEHOLDER'),
      },
      phone: {
        label: t('USER_INFO.PHONE'),
        type: 'number',
        icon: 'mobile',
        info: '',
        placeholder: t('USER_INFO.PHONE_PLACEHOLDER'),
      },
      email: {
        label: t('USER_INFO.EMAIL'),
        type: 'email',
        icon: 'email',
        info: t('USER_INFO.VALIDATIONS.BILLING_NEEDED'),
        placeholder: t('USER_INFO.EMAIL_PLACEHOLDER'),
      },
    };

    if (clusterSettings.value?.basics.customerInfo) {
      // Set values
      const { customerInfo } = clusterSettings.value.basics;
      const keys = Object.keys(customerInfo) as Array<keyof Customer>;

      keys.forEach((key) => {
        // @ts-ignore
        if (customerInfo[key] && fields[key]) values.set(key, fields[key]);
      });
    }

    return values;
  },
);

const rules = computed(
  (): ValidationArgs => ({
    name: {
      required: clusterSettings.value?.basics.customerInfo.name
        ? helpers.withMessage(
          t('USER_INFO.VALIDATIONS.REQUIRED', {
            field: showClientType.value ? t('USER_INFO.NAME_NIT') : t('USER_INFO.NAME'),
          }),
          required,
        )
        : {},
      minLength: helpers.withMessage(
        t('USER_INFO.VALIDATIONS.MIN_LENGTH', {
          field: t('USER_INFO.NAME'),
          length: 2,
        }),
        minLength(2),
      ),
      isNameValid: helpers.withMessage(
        t('USER_INFO.VALIDATIONS.ALPHA'),
        (value: string) => /^[a-zA-Z\s]*$/.test(value),
      ),
    },
    email: {
      email: helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email),
      minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email),
    },
    phone: {},
    taxIdentificationNumber: {
      minLength: helpers.withMessage(
        t('USER_INFO.VALIDATIONS.MIN_LENGTH', {
          field: t('USER_INFO.DOCUMENT_ID'),
          length: 6,
        }),
        minLength(6),
      ),
      maxLength: helpers.withMessage(
        t('USER_INFO.VALIDATIONS.MAX_LENGTH', {
          field: t('USER_INFO.DOCUMENT_ID'),
          length: 10,
        }),
        maxLength(10),
      ),
    },
  }),
);

const v$ = useVuelidate(rules, customer);

const cartActionsDisabled = computed(
  () =>
    currentStepIndex.value === 3
    || !Object.values(collapseSteps.value)[currentStepIndex.value].checked
    || v$.value.$invalid
    || !canContinue.value,
);

/**
 * Create a payment order with specific payment method
 */
const { createOrder } = useCreateOrder();

function selectPaymentMethod(isCash: boolean) {
  createOrder(isCash ? PaymentMethod.CASH : PaymentMethod.CARD);
}

function createOrderWithDifferentCurrency(currencyCode: string) {
  closeCurrencyModal();
  createOrder(PaymentMethod.CARD, currencyCode);
}

function nextStep() {
  const index = currentStepIndex.value;
  if (index !== -1) {
    Object.values(collapseSteps.value)[index].collapse = true;
    Object.values(collapseSteps.value)[index + 1].collapse = false;
    Object.values(collapseSteps.value)[index].checked = true;
  }
}

function previousStep() {
  const index = currentStepIndex.value;
  if (index > 0) {
    Object.values(collapseSteps.value)[index].collapse = true;
    Object.values(collapseSteps.value)[index - 1].collapse = false;
  }
}

function goBack() {
  router.back();
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  if (showClientType.value) {
    customer.value.clientType = false;
  }
  // Preserve the position to display
  if (showCustomerView.value) {
    collapseSteps.value.customerInfo = {
      collapse: false,
      checked: true,
    };
  }

  if (tableController.fulfillment.displayTable) {
    collapseSteps.value.tableNumber = {
      collapse: showCustomerView.value,
      checked: !showCustomerView.value,
    };
  }

  collapseSteps.value.payment = {
    collapse: true,
    checked: false,
  };
});

onUnmounted(() => {
  hideSummary.value = true;
});
</script>

<template>
  <div class="flex flex-col bg-kiosk-background full-hd">
    <section class="flex flex-col justify-between flex-grow overflow-auto">
      <div class="options-container">
        <CollapsableContainer
          v-if="collapseSteps.customerInfo"
          v-model="collapseSteps.customerInfo.collapse"
          :position="
            Object.keys(collapseSteps).findIndex((key) => key === 'customerInfo')
          "
          :title="t('CHECKOUT.CLIENT_INFO_TITLE')"
          :show-collapse="false"
        >
          <OrderCheckoutUserInfoV2
            v-model="customer"
            v-model:vuelidate="v$"
            :show-client-type="showClientType"
            :user-fields="userFields"
          />
        </CollapsableContainer>

        <template v-if="collapseSteps.tableNumber">
          <TableNumber
            v-model="collapseSteps.tableNumber.collapse"
            :position="
              Object.keys(collapseSteps).findIndex((key) => key === 'tableNumber')
            "
            @next="nextStep()"
          />
        </template>

        <!-- Cart summary -->
        <CollapsableContainer
          v-if="collapseSteps.payment"
          v-model="collapseSteps.payment.collapse"
          :position="Object.keys(collapseSteps).findIndex((key) => key === 'payment')"
          :title="t('CHECKOUT.SUMMARY_TITLE')"
          :show-collapse="false"
        >
          <Payment :customer="customer" />
        </CollapsableContainer>
      </div>
    </section>

    <div class="z-0 border-t border-gray-400">
      <CartActions
        :disabled="cartActionsDisabled"
        :action-lbl="$t('NEXT')"
        @next="nextStep()"
        @back="goBack()"
      >
        <template #actions>
          <KioskButton
            @on-click="previousStep()"
            :disabled="currentStepIndex === 0"
            class="flex gap-5 !text-3xl !py-3.5"
            text-size="medium"
          >
            {{ $t('BACK') }}
          </KioskButton>
        </template>
      </CartActions>
    </div>
  </div>
  <CurrencyModal
    v-if="isOpenCurrencyModal"
    @currency-selected="createOrderWithDifferentCurrency($event)"
    @close-modal="closeCurrencyModal()"
  />

  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="customer"
    :order-total="orderTotal"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>

<style scoped>
.options-container {
  @apply flex flex-col my-12 z-20;
}

.options-container:last-child {
  @apply flex-grow;
}

.user-checkbox {
  @apply w-7 h-7 mr-4;
}

.invoice-border {
  @apply w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14;
  /* TODO: Check how make darker color */
  background-image: linear-gradient(to bottom, var(--kiosk-primary-color), #000);
}
</style>
